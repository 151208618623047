const POINT_OF_SALE_AFFILIATE_ID_STORAGE_KEY = "pointOfSaleAffiliateId";
const POINT_OF_SALE_AFFILIATE_NAME_STORAGE_KEY = "pointOfSaleName";

const AFFILIATE_URL_NUMBER_STORAGE_KEY = "affiliateUrlNumber";
const AFFILIATE_NAME_STORAGE_KEY = "affiliateName";
const AFFILIATE_ID_STORAGE_KEY = "affiliateID";

const SUBSCRIBED_POINTS_OF_SALE = "subscribedPointsOfSale";

type StorableAffiliate = {
  affiliateUrlNumber: number;
  affiliateName: string;
  affiliateID: string;
};

const storeAffiliate = (data: StorableAffiliate): void => {
  localStorage.setItem(
    AFFILIATE_URL_NUMBER_STORAGE_KEY,
    String(data.affiliateUrlNumber)
  );
  localStorage.setItem(AFFILIATE_NAME_STORAGE_KEY, String(data.affiliateName));
  localStorage.setItem(AFFILIATE_ID_STORAGE_KEY, String(data.affiliateID));
};

const loadAffiliate = (): StorableAffiliate | null => {
  const affiliateUrlNumber = Number(
    localStorage.getItem(AFFILIATE_URL_NUMBER_STORAGE_KEY)
  );
  const affiliateName = localStorage.getItem(AFFILIATE_NAME_STORAGE_KEY);
  const affiliateID = localStorage.getItem(AFFILIATE_ID_STORAGE_KEY);

  if (!affiliateUrlNumber || !affiliateName || !affiliateID) {
    return null;
  }
  return {
    affiliateUrlNumber,
    affiliateName,
    affiliateID,
  };
};

type StorablePointOfSale = {
  pointOfSaleAffiliateId: number;
  pointOfSaleName: string;
};

const storePointOfSale = (data: StorablePointOfSale): void => {
  localStorage.setItem(
    POINT_OF_SALE_AFFILIATE_ID_STORAGE_KEY,
    String(data.pointOfSaleAffiliateId)
  );
  localStorage.setItem(
    POINT_OF_SALE_AFFILIATE_NAME_STORAGE_KEY,
    String(data.pointOfSaleName)
  );
};

const loadPointOfSale = (): StorablePointOfSale | null => {
  const pointOfSaleAffiliateId = Number(
    localStorage.getItem(POINT_OF_SALE_AFFILIATE_ID_STORAGE_KEY)
  );
  const pointOfSaleName = localStorage.getItem(
    POINT_OF_SALE_AFFILIATE_NAME_STORAGE_KEY
  );

  if (!pointOfSaleAffiliateId || !pointOfSaleName) {
    return null;
  }
  return {
    pointOfSaleAffiliateId,
    pointOfSaleName,
  };
};

const loadSubscribedPointsOfSale = (): number[] | null => {
  const subscribedPointsOfSale = localStorage.getItem(
    SUBSCRIBED_POINTS_OF_SALE
  );

  if (!subscribedPointsOfSale) {
    return null;
  }
  return subscribedPointsOfSale.split(",").map((id) => Number(id));
};

const storeSubscribedPointsOfSale = (subscribedPointsOfSale: string): void => {
  localStorage.setItem(SUBSCRIBED_POINTS_OF_SALE, subscribedPointsOfSale);
};

const clearApplicationData = (config: {
  clearSubscribedPointsOfSaleStorage: boolean;
}): void => {
  localStorage.removeItem(AFFILIATE_URL_NUMBER_STORAGE_KEY);
  localStorage.removeItem(AFFILIATE_NAME_STORAGE_KEY);
  localStorage.removeItem(AFFILIATE_ID_STORAGE_KEY);
  localStorage.removeItem(POINT_OF_SALE_AFFILIATE_ID_STORAGE_KEY);
  localStorage.removeItem(POINT_OF_SALE_AFFILIATE_NAME_STORAGE_KEY);
  if (config.clearSubscribedPointsOfSaleStorage) {
    localStorage.removeItem(SUBSCRIBED_POINTS_OF_SALE);
  }
};

export const localStorageHelper = {
  storeAffiliate,
  loadAffiliate,
  storePointOfSale,
  loadPointOfSale,
  storeSubscribedPointsOfSale,
  loadSubscribedPointsOfSale,
  clearApplicationData,
};
