import { SubscribeToPointsOfSaleAction } from "../_reducers/subscription.reducer";

export const SET_SUBSCRIBED_POINTS_OF_SALE = "set_subscribed_points_of_sale";

const setSubscribedPointsOfSale = (
  pointOfSaleAffiliateIds: number[]
): SubscribeToPointsOfSaleAction => {
  return {
    type: SET_SUBSCRIBED_POINTS_OF_SALE,
    data: {
      pointOfSaleAffiliateIds,
    },
  };
};

export const subscriptionActions = {
  setSubscribedPointsOfSale,
};
