export class ReservedArticle {
  authors: string;
  coverUrlExtraLarge: string;
  coverUrlLarge: string;
  coverUrlMedium: string;
  coverUrlSmall: string;
  ean: string;
  title: string;
  displayPrice: string;
  productType: string;

  constructor(
    authors: string,
    coverUrlExtraLarge: string,
    coverUrlLarge: string,
    coverUrlMedium: string,
    coverUrlSmall: string,
    ean: string,
    title: string,
    displayPrice: string,
    productType: string
  ) {
    this.authors = authors;
    this.coverUrlExtraLarge = coverUrlExtraLarge;
    this.coverUrlLarge = coverUrlLarge;
    this.coverUrlMedium = coverUrlMedium;
    this.coverUrlSmall = coverUrlSmall;
    this.ean = ean;
    this.title = title;
    this.displayPrice = displayPrice;
    this.productType = productType;
  }

  /**
   * Constructs a ReservedArticle object from a service object received from the WSAPI.
   * The corresponding WSAPI class is de.libri.wsapi.service.v1.Reservation.ReservationArticle
   */
  static fromWebserviceObject(json: Record<string, unknown>): ReservedArticle {
    const authors = json["authors"] && String(json["authors"]);
    const coverUrlExtraLarge =
      json["coverUrlExtraLarge"] && String(json["coverUrlExtraLarge"]);
    const coverUrlLarge =
      json["coverUrlLarge"] && String(json["coverUrlLarge"]);
    const coverUrlMedium =
      json["coverUrlMedium"] && String(json["coverUrlMedium"]);
    const coverUrlSmall =
      json["coverUrlSmall"] && String(json["coverUrlSmall"]);
    const ean = json["ean"] && String(json["ean"]);
    const title = json["title"] && String(json["title"]);
    const displayPrice =
      json["priceInCents"] &&
      String((Number(json["priceInCents"]) / 100).toFixed(2)).replace(
        ".",
        ","
      ) + " €";
    const productType = json["productType"] && String(json["productType"]);
    return new ReservedArticle(
      authors,
      coverUrlExtraLarge,
      coverUrlLarge,
      coverUrlMedium,
      coverUrlSmall,
      ean,
      title,
      displayPrice,
      productType
    );
  }
}
