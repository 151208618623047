import { SubscriptionState } from "../_helpers/store";
import { localStorageHelper } from "../_helpers/localStorage";
import { LOGOUT } from "../_actions/authentication.actions";
import { SET_SUBSCRIBED_POINTS_OF_SALE } from "../_actions/subscription.actions";
import { LogoutAction } from "./authentication.reducer";
import { AnyAction } from "redux";

export type SubscribeToPointsOfSaleAction = {
  type: typeof SET_SUBSCRIBED_POINTS_OF_SALE;
  data: {
    pointOfSaleAffiliateIds: number[];
  };
};

const getInitialState = (): SubscriptionState => {
  const existingSubscribedSelectedPointOfSale =
    localStorageHelper.loadSubscribedPointsOfSale();
  if (!existingSubscribedSelectedPointOfSale) {
    return {
      subscribedPointOfSaleAffiliateIDs: [],
    };
  }
  return {
    subscribedPointOfSaleAffiliateIDs: existingSubscribedSelectedPointOfSale,
  };
};

export const subscription = (
  state: SubscriptionState = getInitialState(),
  action: AnyAction
): SubscriptionState => {
  if (action.type === SET_SUBSCRIBED_POINTS_OF_SALE) {
    return {
      subscribedPointOfSaleAffiliateIDs: action.data.pointOfSaleAffiliateIds,
    };
  }
  if (action.type === LOGOUT) {
    // A manual logout resets the point-of-sale selection,
    // while an automatic one does not:
    if (action.data.isAutomaticLogout) {
      return state;
    }
    return {
      subscribedPointOfSaleAffiliateIDs: [],
    };
  }
  return state;
};

export type SubscriptionAction = SubscribeToPointsOfSaleAction | LogoutAction;
