import {
  ERROR_MESSAGE,
  SUCCESS_MESSAGE,
  MODAL_NOTIFICATION_MESSAGE,
} from "../_actions/alert.actions";
import { configureStore } from "@reduxjs/toolkit";
import { authentication } from "../_reducers/authentication.reducer";
import { alert } from "../_reducers/alert.reducer";
import { pointOfSale } from "../_reducers/pointOfSale.reducer";
import { subscription } from "../_reducers/subscription.reducer";

export const store = configureStore({
  reducer: {
    authentication: authentication,
    alert: alert,
    pointOfSale: pointOfSale,
    subscription: subscription,
  },
});

export interface RootState {
  authentication: AuthenticationState;
  alert: AlertState;
  pointOfSale: PointOfSaleState | null;
  subscription: SubscriptionState;
}

// export type AuthenticationState = LoggedOutState | LoggedInState;
export interface AuthenticationState {
  loggedIn: boolean;
  loggingIn: boolean;
  affiliateUrlNumber?: number; // FIXME André: AuthenticationState sollte LoggedInState | LoggedOutState sein, aber dann beschweren sich die test-utils.tsx
  affiliateName?: string;
}

export interface AlertState {
  type?:
    | typeof SUCCESS_MESSAGE
    | typeof ERROR_MESSAGE
    | typeof MODAL_NOTIFICATION_MESSAGE;
  message?: string;
}

export interface PointOfSaleState {
  id: number;
  name: string;
}

export type SubscriptionState = {
  subscribedPointOfSaleAffiliateIDs: number[];
};
