import React, { MouseEventHandler } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../_helpers/store";
import {
  alertActions,
  ERROR_MESSAGE,
  MODAL_NOTIFICATION_MESSAGE,
  SUCCESS_MESSAGE,
} from "../_actions/alert.actions";
import CloseButton from "react-bootstrap/CloseButton";

export const Alert: React.FunctionComponent = () => {
  const { type, message } = useSelector((state: RootState) => state.alert);

  const dispatch = useDispatch();

  const onClose: MouseEventHandler = (e) => {
    e.preventDefault();
    dispatch(alertActions.clear());
  };

  let headerClassName: string,
    bodyClassName: string,
    closeButtonVariant: string,
    headerText: string,
    zIndex: number;

  if (!type) {
    return null;
  } else if (type === SUCCESS_MESSAGE) {
    headerText = "Hinweis";
    headerClassName = "bg-success text-white";
    bodyClassName = "bg-white";
    closeButtonVariant = "white";
    zIndex = 1031;
  } else if (type === ERROR_MESSAGE) {
    headerText = "Fehler";
    headerClassName = "bg-danger text-white";
    bodyClassName = "bg-white";
    closeButtonVariant = "white";
    zIndex = 1031;
  } else if (type === MODAL_NOTIFICATION_MESSAGE) {
    headerText = "Hinweis";
    headerClassName = "bg-warning text-white";
    bodyClassName = "bg-white";
    closeButtonVariant = "white";
    zIndex = 1061;
  } else {
    console.log("Unknown alert 'type': " + type);
    return null;
  }
  /*The Z-index of the toaster should be higher than that of the header($zindex-fixed:1030), so the z-index of the toaster is set to 1031
   In case of showing an error toaster in a modal, we use a z-index of 1061, so it is visible on top of the modal*/
  return (
    <div
      aria-live="assertive"
      aria-atomic="true"
      className="d-flex justify-content-center fixed-top mt-5 w-100 z-index"
      style={{ zIndex: zIndex }}
    >
      <div className="toast show" role="button" onClick={onClose}>
        <div className={"toast-header " + headerClassName}>
          <strong className="me-auto">{headerText}</strong>
          <CloseButton
            className="ms-2 mb-1"
            data-dismiss="toast"
            variant={closeButtonVariant}
            aria-label="Schließen"
            onClick={onClose}
          />
        </div>
        <div className={"toast-body " + bodyClassName}>{message}</div>
      </div>
    </div>
  );
};
