import React, { FunctionComponent, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import { LogoutButton } from "../layout/LogoutButton";
import { usePointOfSaleService } from "../_services/pointOfSale.service";
import { PointOfSale } from "../_models/pointOfSale";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../_helpers/store";
import { pointOfSaleActions } from "../_actions/pointOfSale.actions";
import { alertActions } from "../_actions/alert.actions";
import { authenticationActions } from "../_actions/authentication.actions";
import { Routes } from "../_helpers/routes";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { faBellSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthenticationService } from "../_services/authentication.service";
import { useSubscriptionService } from "../_services/subscription.service";
import { useBaseService } from "../_services/base.service";
import { useResiErrorHandler } from "../_helpers/errorHandler";
import "../App.css";

export const ChoosePointOfSaleView: FunctionComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errorBoundaryHandler = useResiErrorHandler();

  const baseService = useBaseService();
  const pointOfSaleService = usePointOfSaleService();
  const authenticationService = useAuthenticationService(baseService);
  const subscriptionService = useSubscriptionService();

  const logout = () => {
    dispatch(
      authenticationActions.logout(subscriptionService, authenticationService)
    );
  };

  const [pointsOfSale, setPointsOfSale] = useState<PointOfSale[] | null>(null);

  const affiliateUrlNumber = useSelector(
    (state: RootState) => state.authentication.affiliateUrlNumber
  );

  useEffect(() => {
    if (!affiliateUrlNumber) {
      return;
    }
    let mounted = true;
    const loadData = () => {
      pointOfSaleService
        .loadPointsOfSale(affiliateUrlNumber)
        .then((pointsOfSale) => {
          if (mounted) {
            setPointsOfSale(pointsOfSale);
          }
        })
        .catch(errorBoundaryHandler);
    };
    if (!pointsOfSale) {
      loadData();
    }
    return function cleanup() {
      mounted = false;
    };
  }, [
    affiliateUrlNumber,
    pointsOfSale,
    pointOfSaleService,
    errorBoundaryHandler,
  ]);

  const subscribedPointOfSaleAffiliateIDs = useSelector(
    (state: RootState) => state.subscription.subscribedPointOfSaleAffiliateIDs
  );

  useEffect(() => {
    subscriptionService.updateSubscriptionsForPointOfSale(
      new Set<number>(subscribedPointOfSaleAffiliateIDs)
    );
    // Important: Do not include "subscribedPointOfSaleAffiliateIDs" in the dependency list for this hook!
    // Refreshing the subscriptions like we do above will touch the list of subscribed affiliate IDs in the Redux store,
    // and this will cause the whole hook to run again, resulting in an endless loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionService]);

  // We should not be able to get here without an affiliate selected. But in case we do,
  // we can only get the affiliate from the login response. So log out the user and display an error message.
  if (!affiliateUrlNumber) {
    dispatch(
      authenticationActions.logout(subscriptionService, authenticationService)
    );
    dispatch(
      alertActions.error(
        "Ein unerwarteter Fehler ist aufgetreten. Bitte laden Sie die Seite neu, um es nochmals zu versuchen."
      )
    );
    return null;
  }

  // While loading, do not display anything. This could also be a suitable spinner in the future.
  if (!pointsOfSale) {
    return null;
  }

  const _renderBody = () => {
    return (
      <React.Fragment>
        <Container className="bg-transparent px-5">
          <Table striped>
            <thead>
              <tr>
                <th>Name</th>
                <th>Adresse</th>
                <th className="text-center">Benachrichtigungen</th>
                <th>Aktion</th>
              </tr>
            </thead>
            <tbody>{_renderPointOfSaleRows}</tbody>
          </Table>
        </Container>
        <Container className="bg-transparent pt-0 px-5">
          Den Mindestbestand für Reservierungen können Sie im CMS unter dem
          Menüpunkt “Filialen” ändern.
        </Container>
      </React.Fragment>
    );
  };

  const _renderPointOfSaleRows = pointsOfSale.map((pos) => {
    const choosePointOfSale = () => {
      dispatch(
        pointOfSaleActions.choosePointOfSale({
          pointOfSaleAffiliateId: pos.id,
          pointOfSaleName: pos.name,
        })
      );
      navigate(Routes.reservationsRoute);
    };

    const pointOfSaleIsSubscribed = subscribedPointOfSaleAffiliateIDs.includes(
      pos.id
    );

    return (
      <tr key={pos.id}>
        <td>{pos.name}</td>
        <td>
          {pos.addressLine1}
          <br />
          {pos.addressLine2}
        </td>
        <td className="text-center align-middle">
          {pointOfSaleIsSubscribed ? (
            <FontAwesomeIcon
              icon={faBell}
              title="Benachrichtigungen für diese Filiale sind aktiviert"
            />
          ) : (
            <FontAwesomeIcon
              icon={faBellSlash}
              title="Benachrichtigungen für diese Filiale sind deaktiviert"
            />
          )}
        </td>
        <td className="align-middle">
          <Button variant="primary" onClick={choosePointOfSale}>
            Filiale auswählen
          </Button>
        </td>
      </tr>
    );
  });

  return (
    <div className="h-100">
      <header>
        <Navbar
          bg="white"
          expand={false}
          fixed="top"
          className="border-bottom border-dark"
        >
          <div className="mx-auto">
            <div className="float-end">
              <LogoutButton logoutAction={logout} />
            </div>
          </div>
        </Navbar>
      </header>
      <main className="main flex-shrink-0" role="main">
        <div className="mx-auto">
          <h1>Bearbeitung von Reservierungsanfragen</h1>
          <h2>Filialauswahl</h2>
          <div className="bg-white border border-dark">{_renderBody()}</div>
        </div>
      </main>
    </div>
  );
};
