import { BaseService } from "./base.service";
import {
  InvalidCredentialsError,
  MissingPermissionError,
} from "./serviceErrors";
import { cookieHelper } from "../_helpers/cookies";
import { localStorageHelper } from "../_helpers/localStorage";
import { useMemo } from "react";

export type LoginResult = LoginResultSuccess | LoginResultAccessDenied;

export enum LoginResultCode {
  Success,
  InvalidCredentials,
  MissingPermission,
}

export type LoginResultSuccess = {
  resultCode: LoginResultCode.Success;
  data: {
    affiliateUrlNumber: number;
    affiliateName: string;
    affiliateID: string;
  };
};

export type LoginResultAccessDenied = {
  resultCode:
    | LoginResultCode.InvalidCredentials
    | LoginResultCode.MissingPermission;
};

type Context = {
  contextType: string;
  urlNr: number;
  name: string;
  id: string;
};

export class AuthenticationService {
  private readonly _baseService: BaseService;

  constructor(baseService: BaseService) {
    this._baseService = baseService;
  }

  async login(username: string, password: string): Promise<LoginResult> {
    try {
      const data = await this._baseService.post(
        "/wsapi/rest/v1/cmsauth/loginresi",
        {
          username: username,
          password: password,
        },
        false
      );

      const affiliateContext = extractFirstAffiliateContext(
        (data as Record<string, unknown>).contexts as Context[]
      );

      if (!affiliateContext) {
        throw new Error("No affiliate context found in response: " + data);
      }

      return {
        resultCode: LoginResultCode.Success,
        data: {
          affiliateUrlNumber: affiliateContext.urlNr,
          affiliateName: affiliateContext.name,
          affiliateID: affiliateContext.id,
        },
      };
    } catch (e) {
      if (e instanceof InvalidCredentialsError) {
        return {
          resultCode: LoginResultCode.InvalidCredentials,
        };
      } else if (e instanceof MissingPermissionError) {
        return {
          resultCode: LoginResultCode.MissingPermission,
        };
      } else {
        throw e;
      }
    }
  }

  logout(
    config: { isAutomaticLogout: boolean } = {
      isAutomaticLogout: false,
    }
  ): void {
    const tokenId = cookieHelper.getCookie("tokenId");
    if (config.isAutomaticLogout) {
      cookieHelper.clearApplicationData();
      localStorageHelper.clearApplicationData({
        clearSubscribedPointsOfSaleStorage: false,
      });
    } else {
      // Only try to invalidate the token if we log out manually - an automatic logout
      // happens exactly because the token is already invalid, thus the invaldate-request
      // would fail with "expired token" once more:
      if (tokenId) {
        this._baseService
          .post("/wsapi/rest/v1/authentication/invalidatetoken", {
            tokenId: tokenId,
          })
          .catch((e) => {
            console.error(
              `Could not invalidate token: ${e.name} - ${e.message}`
            );
          })
          .finally(() => {
            cookieHelper.clearApplicationData();
            localStorageHelper.clearApplicationData({
              clearSubscribedPointsOfSaleStorage: true,
            });
          });
      }
    }
  }
}

const extractFirstAffiliateContext = (
  contexts: Context[]
): Context | undefined => {
  return contexts.find((record) => record.contextType === "AFFILIATE");
};

export const useAuthenticationService = (
  baseService: BaseService
): AuthenticationService => {
  return useMemo(() => new AuthenticationService(baseService), [baseService]);
};
