import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
} from "../_actions/authentication.actions";
import { AuthenticationState } from "../_helpers/store";
import { localStorageHelper } from "../_helpers/localStorage";

const getInitialState = (): AuthenticationState => {
  const loggedIn = document.cookie
    .split(";")
    .some((item) => item.trim().startsWith("tokenId="));
  if (loggedIn) {
    const existingStoredAffiliate = localStorageHelper.loadAffiliate();
    if (!existingStoredAffiliate) {
      console.log(
        "Cookie suggests user is logged in, but no affiliate number or name was found in local storage. Logging out..."
      );
      return { loggedIn: false, loggingIn: false };
    }
    return {
      loggedIn: true,
      loggingIn: false,
      affiliateName: existingStoredAffiliate.affiliateName,
      affiliateUrlNumber: existingStoredAffiliate.affiliateUrlNumber,
    };
  } else {
    return { loggedIn: false, loggingIn: false };
  }
};

export const authentication = (
  state: AuthenticationState = getInitialState(),
  action: AuthenticationAction
): AuthenticationState => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        loggedIn: false,
        loggingIn: true,
      };
    case LOGIN_SUCCESS:
      return {
        loggedIn: true,
        loggingIn: false,
        affiliateUrlNumber: action.data.affiliateUrlNumber,
        affiliateName: action.data.affiliateName,
      };
    case LOGIN_FAILURE:
      return { loggedIn: false, loggingIn: false };

    case LOGOUT:
      return { loggedIn: false, loggingIn: false };
    default:
      return state;
  }
};

export interface LoginFailureAction {
  type: typeof LOGIN_FAILURE;
}

export interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  data: {
    affiliateUrlNumber: number;
    affiliateName: string;
  };
}

export interface LoginRequestAction {
  type: typeof LOGIN_REQUEST;
}

export interface LogoutAction {
  type: typeof LOGOUT;
  data: {
    isAutomaticLogout: boolean;
  };
}

export type AuthenticationAction =
  | LoginFailureAction
  | LoginSuccessAction
  | LoginRequestAction
  | LogoutAction;
