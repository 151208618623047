import { Dispatch } from "redux";
import { localStorageHelper } from "../_helpers/localStorage";
import { BaseService, useBaseService } from "./base.service";
import { subscriptionActions } from "../_actions/subscription.actions";
import { alertActions } from "../_actions/alert.actions";
import { useDispatch } from "react-redux";
import { TokenExpiredError } from "./serviceErrors";
import { useMemo } from "react";

/**
 * Information that is required to send notifications via the Web Push API.
 * clientPushUrl, clientPublicKey and clientAuth are extracted from the
 * PushSubscription object obtained from the browser's service worker.
 */
type SubscriptionPayload = {
  clientPushUrl: string;
  clientPublicKey: string;
  clientAuth: string;
};

export class SubscriptionService {
  private readonly _baseService: BaseService;
  private readonly _dispatch: Dispatch;

  constructor(baseService: BaseService, dispatch: Dispatch) {
    this._baseService = baseService;
    this._dispatch = dispatch;
  }

  async addSubscriptionForPointOfSale(
    addedPosAffiliateId: number,
    previousSubscribedPointOfSaleIds: number[]
  ): Promise<void> {
    // Use a Set to ensure that there are no duplicates in the IDs:
    const newSubscribedPointsOfSale = new Set(previousSubscribedPointOfSaleIds);
    newSubscribedPointsOfSale.add(addedPosAffiliateId);

    await this.updateSubscriptionsForPointOfSale(
      newSubscribedPointsOfSale,
      "aktiviert"
    );
  }

  async removeSubscriptionForPointOfSale(
    removedPosAffiliateId: number,
    previousSubscribedPointOfSaleIds: number[]
  ): Promise<void> {
    // Use a Set to ensure that there are no duplicates in the IDs:
    const newSubscribedPointsOfSale = new Set(previousSubscribedPointOfSaleIds);
    newSubscribedPointsOfSale.delete(removedPosAffiliateId);

    await this.updateSubscriptionsForPointOfSale(
      newSubscribedPointsOfSale,
      "deaktiviert"
    );
  }

  /**
   * Unregisters the currently registered push subscription, registers a new one
   * and returns all necessary information about that subscription as a SubscriptionPayload.
   * If an error occurs, returns a string instead, containing an error message for the user.
   */
  async getFreshBrowserSubscriptionPayload(): Promise<
    string | SubscriptionPayload
  > {
    if (!supportsServiceWorker()) {
      console.error(`supportsServiceWorker = false - navigator = ${navigator}`);
      return "Ihr Browser unterstützt die notwendige Technologie 'Service Worker' nicht.";
    }

    // Step 1: Register our service worker script:
    const serviceWorkerRegistration = await navigator.serviceWorker.register(
      "/rsv-service-worker.js"
    );

    if (!supportsPushManager(serviceWorkerRegistration)) {
      console.error(
        `supportsPushManager = false - serviceWorkerRegistration = ${serviceWorkerRegistration}`
      );
      return "Ihr Browser unterstützt die notwendige Technologie 'Push Manager' nicht.";
    }

    // Step 2: Unregister any previously registered subscription:
    const existingPushSubscription =
      await serviceWorkerRegistration.pushManager.getSubscription();
    if (existingPushSubscription) {
      // (SL-1535) If we are already subscribed, remove that subscription by
      // - sending its URL with an empty point-of-sales-list to the WSAPI
      // - and unsubscribing from it in the browser
      // We do this because we cannot be sure that the push URL in the existing subscription
      // will not result in a 410 status code when we push a notification against it:
      await this.unsubscribePushSubscriptionInWSAPI(
        existingPushSubscription.endpoint
      );
      await existingPushSubscription.unsubscribe();
    }

    let serverVapidPublicKey: Uint8Array | undefined = undefined;
    try {
      const encodedVapidPublicKey = (await this._baseService.get(
        "/wsapi/rest/v1/reservation/getreservationclientpublickey",
        {}
      )) as string;
      serverVapidPublicKey = decodeVapidPublicKey(encodedVapidPublicKey);
    } catch (e) {
      // If something goes wrong while fetching the server public key, try without it.
      // Firefox does not require the key anyway (as of 2020).
      if (e instanceof Error) {
        console.error(
          `Could not get VAPID public key from server: ${e.name} - ${e.message}`
        );
      } else {
        console.error(`Could not get VAPID public key from server: ${e})`);
      }
    }

    // Step 3: Retrieve a new PushSubscription from the browser. This might trigger the browser request to allow notifications:
    const newPushSubscription =
      await serviceWorkerRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: serverVapidPublicKey,
      });

    // Return the information about the subscription to the caller:
    const subscriptionPayload =
      convertToSubscriptionPayload(newPushSubscription);
    if (!subscriptionPayload) {
      return "Die technische Konvertierung der Nachrichten-Abonnements ist fehlgeschlagen";
    }
    return subscriptionPayload;
  }

  async updateSubscriptionsForPointOfSale(
    newSubscribedPointsOfSale: Set<number>,
    typeOfActivationMessageText?: string
  ): Promise<void> {
    const subscriptionPayload = await this.getFreshBrowserSubscriptionPayload();

    if (typeof subscriptionPayload === "string") {
      const errorMessage: string = subscriptionPayload;
      if (typeOfActivationMessageText) {
        alertActions.error(
          `Benachrichtigungen für diese Filiale konnten nicht ${typeOfActivationMessageText} werden: ${errorMessage}`
        );
      } else {
        alertActions.error(
          `Beim Abonnieren der Benachrichtigungen für diese Filiale ist ein Fehler aufgetreten: ${errorMessage}`
        );
      }
      return;
    }

    try {
      await this.updatePointsOfSaleInWSAPIAndLocalStorage(
        newSubscribedPointsOfSale,
        subscriptionPayload
      );

      if (typeOfActivationMessageText) {
        this._dispatch(
          alertActions.success(
            `Benachrichtigungen für diese Filiale wurden ${typeOfActivationMessageText}`
          )
        );
      }
    } catch (e) {
      if (e instanceof Error) {
        handleSubscriptionError(e, this._dispatch);
      } else {
        console.error(`Unknown error during subscription: ${e}`);
      }
    }
  }

  /**
   * Updates the subscribed points of sale for the given push subscription payload
   * in both the WSAPI and the local storage.
   */
  async updatePointsOfSaleInWSAPIAndLocalStorage(
    newSubscribedPointsOfSale: Set<number>,
    payload: SubscriptionPayload
  ): Promise<void> {
    const sortedSubscribedPointsOfSale = [...newSubscribedPointsOfSale].sort(
      (a, b) => a - b
    );
    const sortedSubscribedPointsOfSaleSerialized =
      sortedSubscribedPointsOfSale.join(",");
    localStorageHelper.storeSubscribedPointsOfSale(
      sortedSubscribedPointsOfSaleSerialized
    );
    await this.sendToWSAPI(sortedSubscribedPointsOfSaleSerialized, payload);

    this._dispatch(
      subscriptionActions.setSubscribedPointsOfSale([
        ...newSubscribedPointsOfSale,
      ])
    );
  }

  async sendToWSAPI(
    pointsOfSaleCsv: string,
    payload: SubscriptionPayload
  ): Promise<void> {
    await this._baseService.post(
      "/wsapi/rest/v1/reservation/subscribetoreservationnotifications",
      {
        clientPushUrl: payload.clientPushUrl,
        clientPublicKey: payload.clientPublicKey,
        clientAuth: payload.clientAuth,
        posAffiliateIds: pointsOfSaleCsv,
      }
    );
  }

  /**
   * Removes the subscription for all points of sale for the given current
   * push URL from both the WSAPI and the local storage.
   */
  async removeAllPointOfSaleSubscriptions(
    clientPushUrl: string
  ): Promise<void> {
    await this.updatePointsOfSaleInWSAPIAndLocalStorage(
      // Send an empty set of POS IDs in order to delete all subscriptions:
      new Set<number>(),
      {
        clientPushUrl: clientPushUrl,
        // Since the push URL is used to find all the subscriptions to delete,
        // it does not matter what we send as the other parameters:
        clientPublicKey: "",
        clientAuth: "",
      }
    );
  }

  /**
   * Removes the subscription for all points of sale for the given push subscription payload
   * from both the WSAPI, but NOT the local storage.
   * This is necessary when we need to purge a stale push URL from the WSAPI, but
   * the list of subscribed points of sale has not actually changed.
   */
  async unsubscribePushSubscriptionInWSAPI(
    stalePushUrl: string
  ): Promise<void> {
    await this.sendToWSAPI("", {
      clientPushUrl: stalePushUrl,
      // Since the push URL is used to find all the subscriptions to delete,
      // it does not matter what we send as the other parameters:
      clientPublicKey: "",
      clientAuth: "",
    });
  }
}

const supportsServiceWorker = () => {
  return "serviceWorker" in navigator;
};

const supportsPushManager = (
  serviceWorkerRegistration: ServiceWorkerRegistration
) => {
  return "pushManager" in serviceWorkerRegistration;
};

/**
 * Decodes a base64-encoded public VAPID server key into a byte array
 * representation that the browser infrastructure understands.
 * Implementation as per https://github.com/web-push-libs/web-push
 */
const decodeVapidPublicKey = (encodedVapidPublicKey: string): Uint8Array => {
  const padding = "=".repeat((4 - (encodedVapidPublicKey.length % 4)) % 4);
  const base64 = (encodedVapidPublicKey + padding)
    .replace(/-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const convertToSubscriptionPayload = (
  pushSubscription: PushSubscription
): SubscriptionPayload | null => {
  // The "PushSubscription" provided by the browser contains encryption information
  // used to send encrypted notification payloads from the WSAPI through the browser server
  // infrastructure to the user's browser. This data is in byte[] form:
  const clientPublicKeyRaw = pushSubscription.getKey("p256dh");
  const clientAuthRaw = pushSubscription.getKey("auth");

  if (!clientPublicKeyRaw || !clientAuthRaw) {
    console.error(
      `PushSubscription was missing essential data: clientPublicKey: ${clientPublicKeyRaw} - clientAuth: ${clientAuthRaw}`
    );
    return null;
  }

  // Transform ArrayBuffer data to base64-encoded string:
  const clientPublicKey = btoa(
    String.fromCharCode(...new Uint8Array(clientPublicKeyRaw))
  );
  const clientAuth = btoa(
    String.fromCharCode(...new Uint8Array(clientAuthRaw))
  );

  return {
    clientPushUrl: pushSubscription.endpoint,
    clientPublicKey: clientPublicKey,
    clientAuth: clientAuth,
  };
};

const handleSubscriptionError = (e: Error, dispatch: Dispatch) => {
  if (e instanceof TokenExpiredError) {
    // Don't try to handle errors that happen because our token expired --
    // there's a global handler in place to redirect to the login page for that:
    return;
  }
  console.error(
    `Failed to subscribe the user to notifications: ${e.name} - ${e.message}`
  );
  dispatch(
    alertActions.error(
      "Benachrichtigungen für diese Filiale konnten nicht aktiviert werden. Bitte versuchen Sie es später noch einmal."
    )
  );
};

export const useSubscriptionService = (): SubscriptionService => {
  const dispatch = useDispatch();

  const baseService = useBaseService();

  return useMemo(
    () => new SubscriptionService(baseService, dispatch),
    [baseService, dispatch]
  );
};
