import { Dispatch } from "redux";
import {
  LoginResultCode,
  AuthenticationService,
} from "../_services/authentication.service";
import { alertActions } from "./alert.actions";
import {
  LoginFailureAction,
  LoginRequestAction,
  LoginSuccessAction,
  LogoutAction,
} from "../_reducers/authentication.reducer";
import { Routes } from "../_helpers/routes";
import { ChangeContextService } from "../_services/changeContext.service";
import { localStorageHelper } from "../_helpers/localStorage";
import { SubscriptionService } from "../_services/subscription.service";
import { NavigateFunction } from "react-router/dist/lib/hooks";

export const LOGIN_SUCCESS = "login_success";
export const LOGIN_REQUEST = "login_request";
export const LOGIN_FAILURE = "login_failure";
export const LOGOUT = "logout";

const login = (
  username: string,
  password: string,
  authenticationService: AuthenticationService,
  changeContextService: ChangeContextService,
  navigate: NavigateFunction,
  dispatch: Dispatch
): LoginRequestAction => {
  const result = authenticationService.login(username, password);
  result
    .then((loginResult) => {
      if (loginResult.resultCode === LoginResultCode.Success) {
        changeContextService
          .changeContext(loginResult.data.affiliateID)
          .then((changeContextResult) => {
            dispatch(
              authenticationActions.loginSuccess(changeContextResult.data)
            );
            localStorageHelper.storeAffiliate(changeContextResult.data);
            navigate(Routes.reservationsRoute);
          })
          .catch((e) => {
            console.error(
              `Error while changing context: ${e.name} - ${e.message}`
            );
            dispatch(authenticationActions.loginFailure());
            dispatch(
              alertActions.error(
                "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut."
              )
            );
          });
      } else {
        dispatch(authenticationActions.loginFailure());
        if (loginResult.resultCode === LoginResultCode.InvalidCredentials) {
          dispatch(
            alertActions.error(
              "Login war nicht erfolgreich. Bitte prüfen Sie Benutzername und Passwort und versuchen Sie es erneut."
            )
          );
        } else if (
          loginResult.resultCode === LoginResultCode.MissingPermission
        ) {
          dispatch(
            alertActions.modalNotification(
              "Sie sind leider nicht für die Bearbeitung von Reservierungsanfragen freigeschaltet. " +
                "Bitte wenden Sie sich an Ihren Ansprechpartner bei Libri, wenn Sie Zugriff erhalten möchten."
            )
          );
        }
      }
    })
    .catch((e) => {
      console.error(`Error while logging in: ${e.name} - ${e.message}`);
      dispatch(authenticationActions.loginFailure());
      dispatch(
        alertActions.error(
          "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut."
        )
      );
    });
  return { type: LOGIN_REQUEST };
};

const loginSuccess = (loginData: {
  affiliateUrlNumber: number;
  affiliateName: string;
  affiliateID: string;
}): LoginSuccessAction => ({ type: LOGIN_SUCCESS, data: loginData });

const loginFailure = (): LoginFailureAction => ({ type: LOGIN_FAILURE });

const automaticLogout = (
  authenticationService: AuthenticationService
): LogoutAction => {
  authenticationService.logout({ isAutomaticLogout: true });
  return {
    type: LOGOUT,
    data: {
      isAutomaticLogout: true,
    },
  };
};

const logout = (
  subscriptionService: SubscriptionService,
  authenticationService: AuthenticationService
): LogoutAction => {
  unsubscribeFromNotifications(
    authenticationService,
    subscriptionService
  ).finally(() => authenticationService.logout());
  return {
    type: LOGOUT,
    data: {
      isAutomaticLogout: false,
    },
  };
};

/**
 * Unsubscribe from all notification subscriptions.
 */
const unsubscribeFromNotifications = async (
  authenticationService: AuthenticationService,
  subscriptionService: SubscriptionService
) => {
  // When manually logging out, we must also a) remove all subscriptions in the WSAPI
  // for our push URL (for that, we must postpone deleting all the cookies
  // until after, because otherwise we won't be able to call the WSAPI),
  // and b) unsubscribe in the browser's PushManager, which is managed
  // by the service worker.
  try {
    const supportsServiceWorker = "serviceWorker" in navigator;
    if (!supportsServiceWorker) {
      return;
    }
    const serviceWorkerRegistration =
      await navigator.serviceWorker.getRegistration();
    if (!serviceWorkerRegistration) {
      return;
    }
    const pushManagerSubscription =
      await serviceWorkerRegistration.pushManager.getSubscription();
    if (pushManagerSubscription) {
      await subscriptionService.removeAllPointOfSaleSubscriptions(
        pushManagerSubscription.endpoint
      );
      await pushManagerSubscription.unsubscribe();
    }
  } catch (e) {
    console.error("Could not revoke subscriptions on logout", e);
  }
};

export const authenticationActions = {
  login,
  loginSuccess,
  loginFailure,
  logout,
  automaticLogout,
};
