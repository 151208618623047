import { BaseService, useBaseService } from "./base.service";
import { DetailedReservation } from "../_models/detailedReservation";
import { MissingPermissionError } from "./serviceErrors";
import { useMemo } from "react";

export enum ReservationListCode {
  Success,
  MissingPermission,
  UnknownError,
}
export type ReservationListSuccess = {
  resultCode: ReservationListCode.Success;
  data: DetailedReservation[];
};
export type ReservationListUnknownError = {
  resultCode: ReservationListCode.UnknownError;
  error: Error;
};
export type ReservationListAccessDenied = {
  resultCode: ReservationListCode.MissingPermission;
};
export type ReservationListResult =
  | ReservationListSuccess
  | ReservationListAccessDenied
  | ReservationListUnknownError;

export enum ReservationStatus {
  FULFILLED = "FULFILLED",
  DECLINED = "DECLINED",
  NEW = "NEW",
}

export enum CommunicationChannel {
  PHONE = "PHONE",
  EMAIL = "EMAIL",
}

export class ReservationService {
  private readonly _baseService: BaseService;

  constructor(baseService: BaseService) {
    this._baseService = baseService;
  }
  async loadReservationListArchive(
    posAffiliateId: number
  ): Promise<ReservationListResult> {
    try {
      const data = await this._baseService.get(
        "/wsapi/rest/v1/reservation/loadreservationsarchive",
        {
          posAffiliateId: String(posAffiliateId),
        }
      );
      return {
        resultCode: ReservationListCode.Success,
        data: DetailedReservation.listFromWebserviceObject(
          data as Record<string, unknown>
        ),
      };
    } catch (e) {
      if (e instanceof MissingPermissionError) {
        return {
          resultCode: ReservationListCode.MissingPermission,
        };
      }
      throw e;
    }
  }

  async loadReservationList(
    posAffiliateId: number
  ): Promise<ReservationListResult> {
    try {
      const data = await this._baseService.get(
        "/wsapi/rest/v1/reservation/loadreservations",
        {
          posAffiliateId: String(posAffiliateId),
        }
      );
      return {
        resultCode: ReservationListCode.Success,
        data: DetailedReservation.listFromWebserviceObject(
          data as Record<string, unknown>
        ),
      };
    } catch (e) {
      if (e instanceof MissingPermissionError) {
        return {
          resultCode: ReservationListCode.MissingPermission,
        };
      }
      throw e;
    }
  }

  async fulfillReservation(reservationId: number): Promise<void> {
    await this._baseService.post(
      "/wsapi/rest/v1/reservation/updatereservationstatus",
      {
        reservationId: String(reservationId),
        reservationStatus: ReservationStatus.FULFILLED,
        // currently, reservation fulfillment can only be communicated via e-mail:
        communicationChannel: CommunicationChannel.EMAIL,
      }
    );
  }

  async declineReservation(
    reservationId: number,
    via: CommunicationChannel,
    textBlock?: string
  ): Promise<void> {
    let data = {
      reservationId: String(reservationId),
      reservationStatus: ReservationStatus.DECLINED,
      communicationChannel: via,
    };
    if (textBlock) {
      data = { ...data, ...{ individualTextBlock: textBlock } };
    }

    await this._baseService.post(
      "/wsapi/rest/v1/reservation/updatereservationstatus",
      data
    );
  }
}

export const useReservationService = (): ReservationService => {
  const baseService = useBaseService();
  return useMemo(() => new ReservationService(baseService), [baseService]);
};
