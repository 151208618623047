export class PointOfSale {
  id: number;
  name: string;
  addressLine1: string;
  addressLine2: string;

  constructor(
    id: number,
    name: string,
    addressLine1: string,
    addressLine2: string
  ) {
    this.id = id;
    this.name = name;
    this.addressLine1 = addressLine1;
    this.addressLine2 = addressLine2;
  }

  /**
   * Constructs a PointOfSale object from a service object received from the WSAPI.
   * The corresponding WSAPI class is de.libri.wsapi.service.v1.affiliate.Affiliate.
   */
  static fromWebserviceObject(json: Record<string, unknown>): PointOfSale {
    const id = Number(json["plainAffiliateId"]);
    const name = String(json["posName"]);
    const addressLine1 =
      String(json["addressStreet"]) + " " + String(json["addressStreetNumber"]);
    const addressLine2 =
      String(json["addressZip"]) + " " + String(json["addressCity"]);
    return new PointOfSale(id, name, addressLine1, addressLine2);
  }

  /**
   * Constructs an array of PointOfSale objects from a service object received
   * from the WSAPI.
   * The corresponding WSAPI class is de.libri.wsapi.service.v1.affiliate.Affiliate.
   */
  static listFromWebserviceObject(
    json: Record<string, unknown>
  ): PointOfSale[] {
    const items = json["items"] as Record<string, unknown>[];
    return items.map((entry) => {
      return PointOfSale.fromWebserviceObject(entry);
    });
  }
}
