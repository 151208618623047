import { TrackingEvent } from "./trackingEvent";
import { TrackingEventName } from "./trackingEventType";
import { BaseService, useBaseService } from "../_services/base.service";
import { useMemo } from "react";

export type TrackingServiceOptions = {
  trackingUrl: string;
  urlNumber: number;
  revision: string;
  applicationName: string;
};

/**
 * The field "errorCode" in the data sent to the tracking servlet must be a number,
 * otherwise the processing steps based on it will fail. -1 is the generic error code,
 * since we don't really know what exactly went wrong when we process an error.
 */
const GENERAL_CLIENT_ERROR_CODE = -1;

export class TrackingService {
  private readonly _baseService: BaseService;
  options: TrackingServiceOptions;
  constructor(baseService: BaseService) {
    this._baseService = baseService;
    this.options = {
      trackingUrl: "/wsapi/info",
      urlNumber:
        parseInt(window.localStorage.getItem("affiliateUrlNumber") as string) ||
        0,
      revision: process.env.REACT_APP_VERSION || "unknown",
      applicationName: "RESI - Libri Online-Reservierung",
    };
  }

  createTrackingEvent(
    eventName: TrackingEventName,
    data: Record<string, unknown>
  ): TrackingEvent {
    return new TrackingEvent(
      eventName,
      data,
      this.options?.urlNumber,
      this.options?.applicationName,
      this.options?.revision
    );
  }

  async trackEvent(
    eventName: TrackingEventName,
    data: Record<string, unknown>
  ): Promise<unknown> {
    const trackingEvent = this.createTrackingEvent(eventName, data);
    const dataString = JSON.stringify({ events: [trackingEvent.toJson()] });
    const paramString = new URLSearchParams({ data: dataString }).toString();
    const requestUrl = this.options.trackingUrl + "?" + paramString;
    return this._baseService.post(requestUrl, {}, false).catch((e) => {
      return Promise.reject(e);
    });
  }

  trackErrorEvent(error: Error): Promise<unknown> {
    return this.trackEvent(TrackingEventName.ERROR, {
      errorCode: GENERAL_CLIENT_ERROR_CODE,
      errorMessage: error.message,
      errorStack: error.stack,
    });
  }
}

export const useTrackingService = (): TrackingService => {
  const baseService = useBaseService();
  return useMemo(() => new TrackingService(baseService), [baseService]);
};
