import React, { useState } from "react";
import "./LoginView.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authenticationActions } from "../_actions/authentication.actions";
import { RootState } from "../_helpers/store";
import { useAuthenticationService } from "../_services/authentication.service";
import { useChangeContextService } from "../_services/changeContext.service";
import { useBaseService } from "../_services/base.service";

export const LoginView: React.FunctionComponent = () => {
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const { username, password } = inputs;
  const loggingIn = useSelector(
    (state: RootState) => state.authentication.loggingIn
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const baseService = useBaseService();
  const authenticationService = useAuthenticationService(baseService);
  const changeContextService = useChangeContextService();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSubmitted(true);
    if (username && password) {
      await dispatch(
        authenticationActions.login(
          username,
          password,
          authenticationService,
          changeContextService,
          navigate,
          dispatch
        )
      );
    }
  };
  return (
    <div className="container">
      <div className="col-lg-8 p-3 mx-auto">
        <div className="col-lg-8 p-3 mx-auto">
          <div className="d-flex justify-content-center">
            <img
              src="./Libri_Logo2021.svg"
              alt="Libri Logo"
              width="170"
              height="170"
              style={{ marginBottom: "2em" }}
            />
          </div>
          <div style={{ marginBottom: "4em" }}>
            <h1 className="text-center text-muted">Online-Reservierung</h1>
            <h3 className="text-center text-muted">Resi</h3>
          </div>
          <form name="form" onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label" htmlFor="username">
                Benutzername
              </label>
              <input
                type="text"
                name="username"
                id="username"
                value={username}
                onChange={handleChange}
                className={
                  "form-control" + (submitted && !username ? " is-invalid" : "")
                }
              />
              {submitted && !username && (
                <div className="invalid-feedback">
                  Benutzername ist ein Plichtfeld
                </div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="password">
                Passwort
              </label>
              <input
                type="password"
                name="password"
                id="password"
                value={password}
                onChange={handleChange}
                className={
                  "form-control" + (submitted && !password ? " is-invalid" : "")
                }
              />
              {submitted && !password && (
                <div className="invalid-feedback">
                  Passwort ist ein Plichtfeld
                </div>
              )}
            </div>
            <div className="mb-3 text-end">
              <a
                className="text-decoration-none"
                rel="noopener noreferrer"
                target="_blank"
                href="https://cms.buchhandlung.de/password_forgotten"
              >
                Passwort vergessen?
              </a>
            </div>
            <div className="mb-3 justify-content-center d-flex">
              <button
                className="btn btn-primary"
                disabled={!password || !username || loggingIn}
              >
                {loggingIn && (
                  <span className="spinner-border spinner-border-sm me-1" />
                )}
                Anmelden
              </button>
            </div>
            <div className="text-center">
              <small className="text-muted">
                © {new Date().getFullYear().toString()} Libri GmbH
              </small>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
