import React, { useEffect } from "react";
import { Navigate, Route, useLocation } from "react-router-dom";
import { Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { LoginView } from "./login/LoginView";
import { ReservationListView } from "./reservation/ReservationListView";
import { alertActions } from "./_actions/alert.actions";
import { RootState } from "./_helpers/store";
import { Routes as ResiRoutes } from "./_helpers/routes";
import { Alert } from "./alert/Alert";
import { ChoosePointOfSaleView } from "./pointofsale/ChoosePointOfSaleView";
import { ReservationListArchiveView } from "./reservation/ReservationListArchiveView";
import { ErrorBoundary } from "react-error-boundary";
import { FallbackInCaseOfErrorComponent } from "./layout/FallbackInCaseOfErrorComponent";
import { ReloadModal } from "./reloadApp/ReloadModal";

const App: React.FunctionComponent = () => {
  const loggedIn = useSelector(
    (state: RootState) => state.authentication.loggedIn
  );
  const selectedPointOfSale = useSelector(
    (state: RootState) => state.pointOfSale
  );
  const dispatch = useDispatch();
  const location = useLocation();

  // Clear alerts and toasts whenever we move to a different URL:
  useEffect(() => {
    // Clear alert on location change, except when changing to the root route,
    // where we might have to display that an automatic logout has just happened:
    if (location.pathname !== "/") {
      dispatch(alertActions.clear());
    }
  }, [location, dispatch]);

  let currentRoutes;
  if (!loggedIn) {
    currentRoutes = (
      <Routes>
        <Route path={ResiRoutes.root} element={<LoginView />} />
        <Route path="*" element={<Navigate to={ResiRoutes.root} />} />
      </Routes>
    );
  } else if (!selectedPointOfSale) {
    currentRoutes = (
      <Routes>
        <Route
          path={ResiRoutes.choosePointOfSaleRoute}
          element={<ChoosePointOfSaleView />}
        />
        <Route
          path="*"
          element={<Navigate to={ResiRoutes.choosePointOfSaleRoute} />}
        />
      </Routes>
    );
  } else {
    currentRoutes = (
      <Routes>
        <Route
          path={ResiRoutes.choosePointOfSaleRoute}
          element={<ChoosePointOfSaleView />}
        />
        <Route
          path={ResiRoutes.reservationsRoute}
          element={<ReservationListView />}
        />
        <Route
          path={ResiRoutes.archiveRoute}
          element={<ReservationListArchiveView />}
        />
        <Route
          path="*"
          element={<Navigate to={ResiRoutes.reservationsRoute} />}
        />
      </Routes>
    );
  }
  return (
    <React.Fragment>
      <ErrorBoundary FallbackComponent={FallbackInCaseOfErrorComponent}>
        <Alert />
        <ReloadModal intervalInMS={300000} />
        {currentRoutes}
      </ErrorBoundary>
    </React.Fragment>
  );
};
export { App };
