import { PointOfSale } from "../_models/pointOfSale";
import { BaseService, useBaseService } from "./base.service";
import { useMemo } from "react";

export class PointOfSaleService {
  private readonly _baseService: BaseService;

  constructor(baseService: BaseService) {
    this._baseService = baseService;
  }

  async loadPointsOfSale(affiliateUrlNumber: number): Promise<PointOfSale[]> {
    const data = await this._baseService.get(
      "/wsapi/rest/v1/affiliate/findpointofsale",
      {
        urlNumber: String(affiliateUrlNumber),
      }
    );
    return PointOfSale.listFromWebserviceObject(
      data as Record<string, unknown>
    );
  }
}

export const usePointOfSaleService = (): PointOfSaleService => {
  const baseService = useBaseService();
  return useMemo(() => new PointOfSaleService(baseService), [baseService]);
};
