import React from "react";
import { FallbackProps } from "react-error-boundary";
import Button from "react-bootstrap/Button";
import { useTrackingService } from "../_tracking/tracking.service";

/**
 * Component that can be displayed instead of the "main content" of the
 * application when an error happens within that main content that the
 * application cannot recover from. This component is used in the
 * error boundary concept that React uses, see
 * https://reactjs.org/docs/error-boundaries.html
 *
 * @param error The error that lead to this component being displayed.
 * @param resetErrorBoundary A callback to call when the user wants
 * to try loading the child components of the error boundary again.
 */
export const FallbackInCaseOfErrorComponent: React.FunctionComponent<
  FallbackProps
> = ({ error, resetErrorBoundary }: FallbackProps) => {
  const trackingService = useTrackingService();
  trackingService.trackErrorEvent(error as Error);
  return (
    <div className="mx-auto">
      <h1>Bearbeitung von Reservierungsanfragen</h1>
      <div className="bg-white border border-dark">
        <div role="alert">
          <div className="mx-auto w-50 mt-5">
            <div className="alert alert-danger">
              Beim Laden dieser Ansicht ist ein Fehler aufgetreten
            </div>
            <div className="mx-auto">
              <div className="d-grid w-100">
                <Button
                  variant="outline-danger"
                  size="lg"
                  className="mb-5"
                  onClick={resetErrorBoundary}
                >
                  Noch einmal versuchen
                </Button>
              </div>
            </div>
            <p>
              Sollte der Fehler fortbestehen, so teilen Sie uns bitte folgende
              Informationen für die Fehleranalyse mit:
            </p>
            <div className="alert alert-secondary" role="alert">
              <p>{new Date().toLocaleString()}</p>
              <h4>{error?.name}</h4>
              <pre>{error?.message}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
