import { BaseService, useBaseService } from "./base.service";
import { useMemo } from "react";

export type ChangeContextResult = {
  data: {
    affiliateUrlNumber: number;
    affiliateName: string;
    affiliateID: string;
  };
};

export type Context = {
  contextType: string;
  urlNr: number;
  name: string;
  id: string;
};

export class ChangeContextService {
  private readonly _baseService: BaseService;

  constructor(baseService: BaseService) {
    this._baseService = baseService;
  }

  async changeContext(cmsContextId: string): Promise<ChangeContextResult> {
    const data = await this._baseService.post(
      "/wsapi/rest/v1/cmsauth/changecontext",
      {
        cmsContextType: "AFFILIATE",
        cmsContextId: cmsContextId,
      }
    );

    const affiliateContext = (data as Record<string, unknown>)
      .currentContext as Context;
    if (!affiliateContext) {
      throw new Error("No affiliate context found in response: " + data);
    }
    return {
      data: {
        affiliateUrlNumber: affiliateContext.urlNr,
        affiliateName: affiliateContext.name,
        affiliateID: affiliateContext.id,
      },
    };
  }
}

export const useChangeContextService = (): ChangeContextService => {
  const baseService = useBaseService();
  return useMemo(() => new ChangeContextService(baseService), [baseService]);
};
