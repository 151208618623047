import {
  ClearMessageAction,
  ErrorMessageAction,
  ModalErrorMessageAction,
  SuccessMessageAction,
} from "../_reducers/alert.reducer";

export const SUCCESS_MESSAGE = "alert-success";
export const ERROR_MESSAGE = "alert-danger";
export const MODAL_NOTIFICATION_MESSAGE = "alert-modal-danger";
export const CLEAR_MESSAGE = "alert-clear";

const success = (message: string): SuccessMessageAction => {
  return { type: SUCCESS_MESSAGE, message };
};

const error = (message: string): ErrorMessageAction => {
  return { type: ERROR_MESSAGE, message };
};

const modalNotification = (message: string): ModalErrorMessageAction => {
  return { type: MODAL_NOTIFICATION_MESSAGE, message };
};

const clear = (): ClearMessageAction => {
  return { type: CLEAR_MESSAGE };
};

export const alertActions = {
  success,
  error,
  modalNotification,
  clear,
};
