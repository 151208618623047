import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import React, { MouseEventHandler } from "react";

type LogoutButtonProps = {
  logoutAction: MouseEventHandler;
};

export const LogoutButton = (props: LogoutButtonProps): JSX.Element => {
  return (
    <OverlayTrigger
      placement={"bottom"}
      trigger={["hover", "focus"]}
      overlay={
        <Tooltip id={`logout-button-tooltip`}>
          Benachrichtigungen werden deaktiviert
        </Tooltip>
      }
    >
      <Button variant="light" onClick={props.logoutAction} className="border">
        Abmelden
        <FontAwesomeIcon icon={faSignOutAlt} className="ms-2" />
      </Button>
    </OverlayTrigger>
  );
};
