export class HttpStatusError extends Error {
  constructor(url: string, statusCode: number, statusText: string) {
    super(
      `Unexpected status code ${statusCode} (${statusText}) encountered while calling '${url}'`
    );
  }
}

export class InvalidJsonResponseError extends Error {
  constructor(url: string, json: string) {
    super(
      `Received invalid or unexpected JSON after querying '${url}': ${json}`
    );
  }
}

export class TokenExpiredError extends Error {
  constructor(url: string, resultCode: number, resultText: string) {
    super(
      `Expired or invalid token provided when calling '${url}': Result code ${resultCode} - ${resultText}`
    );
  }
}

export class InvalidCredentialsError extends Error {
  constructor(url: string, message: string) {
    super(`Invalid credentials provided when calling '${url}': ${message}`);
  }
}

export class WebserviceError extends Error {
  constructor(url: string, resultCode: number, resultText: string) {
    super(
      `Unexpected result code ${resultCode} (${resultText}) from server when calling '${url}'`
    );
  }
}

export class MissingPermissionError extends Error {
  constructor(url: string, resultCode: number, resultText: string) {
    super(
      `User has no permission for calling '${url}' (Result code ${resultCode}-${resultText})`
    );
  }
}

export class ReservationAlreadyProcessedError extends Error {
  constructor(reservationId: number) {
    super(
      `The reservation with the id '${reservationId}' is no longer editable as it has been handled already by a different client.`
    );
  }
}
