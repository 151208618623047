import React from "react";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import { RootState } from "../_helpers/store";
import { useSubscriptionService } from "../_services/subscription.service";

type SubscribeToNotificationsLinkProps = {
  posAffiliateId: number;
};

const supportsServiceWorker = () => {
  return "serviceWorker" in navigator;
};

export const SubscribeToNotificationsLink: React.FunctionComponent<
  SubscribeToNotificationsLinkProps
> = (props: SubscribeToNotificationsLinkProps) => {
  const subscriptionService = useSubscriptionService();

  const subscribedPointsOfSale = useSelector(
    (state: RootState) => state.subscription.subscribedPointOfSaleAffiliateIDs
  );

  if (!supportsServiceWorker()) {
    return (
      <Button variant="link" disabled>
        Ihr Browser unterstützt leider keine Benachrichtigungen
      </Button>
    );
  }

  const alreadySubscribed = subscribedPointsOfSale.includes(
    props.posAffiliateId
  );

  const onSubscribe = async () => {
    if (alreadySubscribed) {
      await subscriptionService.removeSubscriptionForPointOfSale(
        props.posAffiliateId,
        subscribedPointsOfSale
      );
    } else {
      await subscriptionService.addSubscriptionForPointOfSale(
        props.posAffiliateId,
        subscribedPointsOfSale
      );
    }
  };

  return (
    <Button variant="link" onClick={() => onSubscribe()}>
      {alreadySubscribed
        ? "Benachrichtigungen deaktivieren"
        : "Benachrichtigungen aktivieren"}
    </Button>
  );
};
