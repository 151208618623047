import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faSync,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { DetailedReservation } from "../_models/detailedReservation";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../_helpers/store";
import {
  ReservationListCode,
  ReservationListResult,
  ReservationStatus,
  useReservationService,
} from "../_services/reservation.service";
import { alertActions } from "../_actions/alert.actions";
import moment from "moment";
import { ReservationCoverZoomModal } from "./ReservationCoverZoomModal";
import { LogoutButton } from "../layout/LogoutButton";
import { authenticationActions } from "../_actions/authentication.actions";
import { Routes } from "../_helpers/routes";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import DropdownButton from "react-bootstrap/DropdownButton";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import { useSubscriptionService } from "../_services/subscription.service";
import { useResiErrorHandler } from "../_helpers/errorHandler";
import { useAuthenticationService } from "../_services/authentication.service";
import { useBaseService } from "../_services/base.service";
import "../App.css";

export const ReservationListArchiveView: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const errorBoundaryHandler = useResiErrorHandler();

  const baseService = useBaseService();
  const reservationService = useReservationService();
  const subscriptionService = useSubscriptionService();
  const authenticationService = useAuthenticationService(baseService);

  // Which point of sale are we displaying reservations for?
  const pointOfSale = useSelector((state: RootState) => state.pointOfSale);

  if (!pointOfSale) {
    throw new Error("The pointOfSale should never be undefined!");
  }

  const pointOfSaleAffiliateId = pointOfSale.id;
  // What is the list of reservations we are displaying?
  const [reservations, setReservations] = useState<
    DetailedReservation[] | null
  >(null);
  const [currentReservationCover, setCurrentReservationCover] = useState<
    string | null
  >(null);
  const onReservationModalClose = () => {
    setCurrentReservationCover(null);
    dispatch(alertActions.clear());
  };

  // Are we loading?
  const [loadingInProgess, setLoadingInProgess] = useState<boolean>(false);

  // Loads the data and returns a promise to it:
  const loadData = useCallback(
    async (
      pointOfSaleAffiliateId: number
    ): Promise<ReservationListResult | void> => {
      setLoadingInProgess(true);
      return reservationService
        .loadReservationListArchive(pointOfSaleAffiliateId)
        .catch(errorBoundaryHandler)
        .finally(() => setLoadingInProgess(false));
    },
    [reservationService, errorBoundaryHandler]
  );

  // Displays the data loaded by "loadData".
  // Separating this from "loadData" is necessary because we need to handle
  // the case of the component already having unmounted in the useEffect hook
  // that displays the data when the list is first entered:
  const displayData = useCallback(
    (reservationListResult: ReservationListResult | void) => {
      if (!reservationListResult) {
        return;
      }
      if (reservationListResult.resultCode === ReservationListCode.Success) {
        setReservations(reservationListResult.data);
      } else if (
        reservationListResult.resultCode ===
        ReservationListCode.MissingPermission
      ) {
        dispatch(
          alertActions.error(
            "Sie sind leider nicht für die Bearbeitung von Reservierungsanfragen freigeschaltet. Bitte wenden Sie sich an Libri, wenn Sie Zugriff erhalten möchten."
          )
        );
      } else if (
        reservationListResult.resultCode === ReservationListCode.UnknownError
      ) {
        dispatch(
          alertActions.error(
            "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut."
          )
        );
      }
    },
    [dispatch]
  );

  // Load the reservation data when the list is first entered:
  useEffect(() => {
    let mounted = true;
    loadData(pointOfSaleAffiliateId).then((result) => {
      // Only try to display the data (= modify the component state) if
      // the component is still mounted (might not be the case if we
      // navigate away from the list while the data is loading):
      if (mounted) {
        displayData(result);
      }
    });
    return function cleanup() {
      mounted = false;
    };
  }, [pointOfSaleAffiliateId, loadData, displayData, dispatch]);

  const refresh = () => {
    loadData(pointOfSaleAffiliateId).then(displayData);
  };

  const logout = () => {
    dispatch(
      authenticationActions.logout(subscriptionService, authenticationService)
    );
  };

  const toNavigationLink = () => {
    navigate(Routes.choosePointOfSaleRoute);
  };

  const _renderRefreshButton = () => {
    if (!loadingInProgess) {
      return (
        <Button
          variant="primary"
          onClick={refresh}
          className="border float-end"
        >
          Anzeige aktualisieren
          <FontAwesomeIcon icon={faSync} className="ms-2" />
        </Button>
      );
    }
    return (
      <Button variant="primary" className="border float-end" disabled>
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      </Button>
    );
  };

  const _renderBody = () => {
    if (!reservations) {
      return <span className="spinner-border spinner-border-sm me-1" />;
    } else if (reservations.length === 0) {
      return <h2>Keine Reservierungen vorhanden</h2>;
    } else {
      return (
        <React.Fragment>
          <ReservationCoverZoomModal
            onModalClose={onReservationModalClose}
            imageUrl={currentReservationCover}
          />
          {_renderTable(reservations)}
        </React.Fragment>
      );
    }
  };

  const _renderTable = (
    detailedReservations: DetailedReservation[]
  ): JSX.Element => {
    return (
      <div
        className="container-fluid justify-content-around table-responsive"
        style={{ padding: "1em" }}
      >
        <table
          className="table table-striped table-hover"
          style={{ margin: "0em" }}
        >
          {_renderTableDescription()}
          <tbody>
            {detailedReservations
              .filter(
                (detailedReservation) =>
                  detailedReservation.status !== ReservationStatus.NEW
              )
              .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
              .map((detailedReservation: DetailedReservation, index: number) =>
                _renderTableEntry(detailedReservation, index)
              )}
          </tbody>
        </table>
      </div>
    );
  };

  const _renderTableDescription = () => {
    return (
      <thead>
        <tr>
          <th className="width-100 text-center">Nummer</th>
          <th className="width-100 text-center">Datum</th>
          <th className="width-100 text-center">Name</th>
          <th className="width-100 text-center">E-Mail</th>
          <th className="width-100 text-center">Telefon</th>
          <th className="width-100 text-center">Cover</th>
          <th className="width-100 text-center">Titel</th>
          <th className="width-100 text-center">Autor</th>
          <th className="width-100 text-center">ean</th>
          <th className="width-100 text-center">Preis</th>
          <th className="width-100 text-center">Status</th>
          <th className="width-100 text-center">Bearbeitet</th>
        </tr>
      </thead>
    );
  };

  const _renderTableEntry = (
    reservation: DetailedReservation,
    index: number
  ): JSX.Element => {
    const dateCreatedAt: Date = reservation.reservationDate;
    const dateCreatedAtString = moment(dateCreatedAt).format("D.M.YYYY");
    const timeCreatedAtString = moment(dateCreatedAt).format("H:mm [Uhr]");
    const dateUpdatedAt: Date | null | undefined = reservation.processingDate;
    const dateUpdatedAtString = moment(dateUpdatedAt).format("D.M.YYYY");
    const timeUpdatedAtString = moment(dateUpdatedAt).format("H:mm [Uhr]");
    const thumbsUpIcon = (
      <span
        className="fa-layers fa-fw fa-3x"
        title="Reservierungsanfrage Bestätigt"
      >
        <FontAwesomeIcon icon={faThumbsUp} color="green" transform="shrink-6" />
      </span>
    );
    const thumbsDownIcon = (
      <span
        className="fa-layers fa-fw fa-3x"
        title="Reservierungsanfrage Abgelehnt"
      >
        <FontAwesomeIcon icon={faThumbsDown} color="red" transform="shrink-6" />
      </span>
    );

    return (
      <tr key={index}>
        <td className="text-center align-middle">{index + 1}</td>
        <td className="text-center align-middle">
          {dateCreatedAtString}
          <br />
          {timeCreatedAtString}
        </td>
        <td className="text-center align-middle">{reservation.name}</td>
        <td className="text-center align-middle">{reservation.email}</td>
        <td className="text-center align-middle">{reservation.phone}</td>
        <td className="text-center align-middle">
          <img
            className="img-responsive center-block"
            src={reservation.article.coverUrlSmall}
            alt="Cover"
            height="60"
          />
        </td>
        <td className="text-center align-middle">
          {reservation.article.title}
        </td>
        <td className="text-center align-middle">
          {reservation.article.authors}
        </td>
        <td className="text-center align-middle">{reservation.article.ean}</td>
        <td className="text-center align-middle">
          {reservation.article.displayPrice}
        </td>
        <td className="text-center align-middle ">
          {reservation.status === ReservationStatus.FULFILLED
            ? thumbsUpIcon
            : thumbsDownIcon}
        </td>
        <td className="text-center align-middle">
          {dateUpdatedAtString}
          <br />
          {timeUpdatedAtString}
        </td>
      </tr>
    );
  };

  const goToReservations = () => {
    navigate(Routes.reservationsRoute);
  };

  return (
    <div className="h-100">
      <header>
        <Navbar
          bg="white"
          expand={false}
          fixed="top"
          className="border-bottom border-dark"
        >
          <div className="mx-auto">
            <div className="float-start">
              <Button
                variant="light"
                onClick={toNavigationLink}
                className="border"
              >
                <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                Andere Filiale auswählen
              </Button>
            </div>
            <div className="float-end btn-group">
              <div className="pe-2">
                <DropdownButton
                  id="dropdown-basic-button"
                  title="Archiv"
                  variant="secondary"
                >
                  <Dropdown.Item href="#/action-1">Archiv</Dropdown.Item>
                  <Dropdown.Item onClick={goToReservations}>
                    Reservierungsanfragen
                  </Dropdown.Item>
                </DropdownButton>
              </div>
              <div className="pe-2">
                <LogoutButton logoutAction={logout} />
              </div>
            </div>
          </div>
        </Navbar>
      </header>
      <main className="main flex-shrink-0" role="main">
        <div className="mx-auto">
          <h1>Archiv - Bearbeitete Reservierungen</h1>
          <h2>
            {pointOfSale.name} {_renderRefreshButton()}
          </h2>
          <div className="bg-white border border-dark">{_renderBody()}</div>
        </div>
      </main>
    </div>
  );
};
