import {
  CLEAR_MESSAGE,
  ERROR_MESSAGE,
  MODAL_NOTIFICATION_MESSAGE,
  SUCCESS_MESSAGE,
} from "../_actions/alert.actions";
import { AlertState } from "../_helpers/store";
import { LogoutAction } from "./authentication.reducer";
import { LOGOUT } from "../_actions/authentication.actions";

export const alert = (
  state: AlertState = {},
  action: AlertAction
): AlertState => {
  switch (action.type) {
    case SUCCESS_MESSAGE:
      return {
        type: SUCCESS_MESSAGE,
        message: action.message,
      };
    case ERROR_MESSAGE:
      return {
        type: ERROR_MESSAGE,
        message: action.message,
      };
    case MODAL_NOTIFICATION_MESSAGE:
      return {
        type: MODAL_NOTIFICATION_MESSAGE,
        message: action.message,
      };
    case CLEAR_MESSAGE:
      return {};
    case LOGOUT:
      if (action.data.isAutomaticLogout) {
        // Show an information if the user gets logged out automatically:
        return {
          type: ERROR_MESSAGE,
          message: "Sitzung abgelaufen. Bitte loggen Sie sich erneut ein.",
        };
      }
      return {};
    default:
      return state;
  }
};

export interface SuccessMessageAction {
  type: typeof SUCCESS_MESSAGE;
  message: string;
}

export interface ErrorMessageAction {
  type: typeof ERROR_MESSAGE;
  message: string;
}

export interface ModalErrorMessageAction {
  type: typeof MODAL_NOTIFICATION_MESSAGE;
  message: string;
}

export interface ClearMessageAction {
  type: typeof CLEAR_MESSAGE;
}

export type AlertAction =
  | SuccessMessageAction
  | ErrorMessageAction
  | ModalErrorMessageAction
  | ClearMessageAction
  | LogoutAction;
