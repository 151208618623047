import { ReservedArticle } from "./reservedArticle";

export class DetailedReservation {
  id: number;
  posAffiliateId: number;
  name: string;
  createdAt: Date; // automatically set in DB and prone to unintentional changes, we rely on "reservationDate" instead
  updatedAt: Date | null | undefined; // automatically set in DB and prone to unintentional changes, we rely on "processing date" instead
  email: string;
  phone: string;
  salutation: string;
  status: string;
  article: ReservedArticle;
  reservationDate: Date;
  processingDate: Date | null | undefined;
  editedBy: string | null | undefined;

  constructor(
    id: number,
    posAffiliateId: number,
    name: string,
    createdAt: Date,
    updatedAt: Date | null | undefined,
    email: string,
    phone: string,
    salutation: string,
    status: string,
    article: ReservedArticle,
    reservationDate: Date,
    processingDate: Date,
    editedBy: string
  ) {
    this.id = id;
    this.posAffiliateId = posAffiliateId;
    this.name = name;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.email = email;
    this.phone = phone;
    this.salutation = salutation;
    this.status = status;
    this.article = article;
    this.reservationDate = reservationDate;
    this.processingDate = processingDate;
    this.editedBy = editedBy;
  }

  /**
   * Constructs a DetailedReservation object from a service object received from the WSAPI.
   * The corresponding WSAPI class is de.libri.wsapi.service.v1.Reservation.DetailedReservation.
   */
  static fromWebserviceObject(
    json: Record<string, unknown>
  ): DetailedReservation {
    const id = json["id"] && Number(json["id"]);
    const posAffiliateId =
      json["posAffiliateId"] && Number(json["posAffiliateId"]);
    let name = "";
    if (json["lastName"] && json["firstName"]) {
      name = String(json["lastName"]) + ", " + String(json["firstName"]);
    } else if (json["lastName"]) {
      name = String(json["lastName"]);
    } else if (json["firstName"]) {
      name = String(json["firstName"]);
    }
    const createdAt = json["createdAt"] && new Date(String(json["createdAt"]));
    const updatedAt = json["updatedAt"] && new Date(String(json["updatedAt"]));
    const email = json["email"] && String(json["email"]);
    const phone = json["phone"] && String(json["phone"]);
    const salutation =
      json["salutation"] &&
      String(json["salutation"]).charAt(0).toUpperCase() +
        String(json["salutation"]).slice(1).toLowerCase();
    const status = json["status"] && String(json["status"]);
    const article = ReservedArticle.fromWebserviceObject(
      json["article"] as Record<string, unknown>
    );

    const reservationDate =
      json["reservationDate"] && new Date(String(json["reservationDate"]));
    const processingDate =
      json["processingDate"] && new Date(String(json["processingDate"]));
    const editedBy = json["editedBy"] && String(json["editedBy"]);

    return new DetailedReservation(
      id,
      posAffiliateId,
      name,
      createdAt,
      updatedAt,
      email,
      phone,
      salutation,
      status,
      article,
      reservationDate,
      processingDate,
      editedBy
    );
  }

  /**
   * Constructs an array of DetailedReservation objects from a service object received
   * from the WSAPI.
   * The corresponding WSAPI class is de.libri.wsapi.service.v1.Reservation.DetailedReservation.
   */
  static listFromWebserviceObject(
    json: Record<string, unknown>
  ): DetailedReservation[] {
    const items = json["items"] as Record<string, unknown>[];
    return items.map((entry) => {
      return DetailedReservation.fromWebserviceObject(entry);
    });
  }
}
