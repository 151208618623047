import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

type ReservationCoverZoomModalProps = {
  onModalClose: () => void;
  imageUrl: string | null;
};
export const ReservationCoverZoomModal = ({
  onModalClose,
  imageUrl,
}: ReservationCoverZoomModalProps): React.ReactElement | null => {
  if (imageUrl) {
    return (
      <Modal
        show={true}
        onHide={onModalClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header className="bg-primary text-white">
          <Modal.Title>Coveransicht</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-8 offset-lg-2">
            <div className="col-lg-8 offset-lg-2">
              <div className="d-flex justify-content-center">
                <img
                  className="img-responsive"
                  src={imageUrl}
                  alt="Large Cover"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="me-auto" variant="primary" onClick={onModalClose}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>
    );
  } else {
    return null;
  }
};
