const root = "/";
const choosePointOfSaleRoute = "/filialen";
const reservationsRoute = "/anfragen";
const archiveRoute = "/archiv";

export const Routes = {
  root,
  choosePointOfSaleRoute,
  reservationsRoute,
  archiveRoute,
};
