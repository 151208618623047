import { ChoosePointOfSaleAction } from "../_reducers/pointOfSale.reducer";
import { localStorageHelper } from "../_helpers/localStorage";

export const CHOOSE_POINT_OF_SALE = "choose_point_of_sale";

const choosePointOfSale = (pointOfSaleData: {
  pointOfSaleAffiliateId: number;
  pointOfSaleName: string;
}): ChoosePointOfSaleAction => {
  localStorageHelper.storePointOfSale(pointOfSaleData);
  return {
    type: CHOOSE_POINT_OF_SALE,
    data: pointOfSaleData,
  };
};

export const pointOfSaleActions = {
  choosePointOfSale,
};
