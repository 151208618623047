import { TrackingEventName } from "./trackingEventType";

class TrackingEvent {
  event: TrackingEventName;
  path: string;
  host: string;
  time: number;
  pointOfSale: string | null;
  revision: string | undefined;
  aUrl?: number | undefined;
  shopVariant: string | undefined;
  data: Record<string, unknown>;

  constructor(
    event: TrackingEventName,
    data: Record<string, unknown>,
    aUrl: number | undefined,
    shopVariant: string,
    revision: string | undefined
  ) {
    this.event = event;
    this.path = document.location.pathname;
    this.host = document.location.host;
    this.time = new Date().getTime();
    this.pointOfSale = window.localStorage.getItem("pointOfSaleAffiliateId");
    this.aUrl = aUrl;
    this.shopVariant = shopVariant;
    this.revision = revision;
    this.data = data;
  }

  toJson(): TrackingEvent {
    return {
      event: this.event,
      path: this.path,
      host: this.host,
      time: this.time,
      pointOfSale: this.pointOfSale,
      aUrl: this.aUrl,
      shopVariant: this.shopVariant,
      revision: this.revision,
      data: this.data,
    } as TrackingEvent;
  }
}

export { TrackingEvent };
