import { BaseService, useBaseService } from "./base.service";
import { useMemo } from "react";

/**
 * Indicates Superoperties that are have numbers as values.
 */
type AffiliatePropertyKeysWithNumberValues =
  "/Shop/Artikel/Online-Reservierung/Refresh-Intervall";
type AffiliatePropertiesWithNumberValues = Record<
  AffiliatePropertyKeysWithNumberValues,
  number
>;

/**
 * All Superoperties that RESI uses. This should be the union of all type-grouped superoperties, so
 * AffiliatePropertiesWithNumberValues | AffiliatePropertiesWithStringValues | AffiliatePropertiesWithBooleanValues | ...
 * New typed Superoperties should be added as needed in the future.
 */
export type AffiliateProperties = AffiliatePropertiesWithNumberValues;

export class AffiliatePropertiesService {
  private readonly _baseService: BaseService;

  constructor(baseService: BaseService) {
    this._baseService = baseService;
  }

  async getAffiliateProperties(): Promise<AffiliateProperties> {
    const data = await this._baseService.post(
      "/wsapi/rest/v1/property/properties",
      {}
    );

    const affiliateProperties = (data as Record<string, unknown>)
      .propertiesMap as AffiliateProperties;
    if (!affiliateProperties) {
      throw new Error("No affiliate properties found in response: " + data);
    }
    return affiliateProperties;
  }
}

export const useAffiliatePropertiesService = (): AffiliatePropertiesService => {
  const baseService = useBaseService();
  return useMemo(
    () => new AffiliatePropertiesService(baseService),
    [baseService]
  );
};
