import { TokenExpiredError } from "../_services/serviceErrors";
import { useCallback } from "react";
import { useErrorBoundary } from "react-error-boundary";

/**
 * A hook that can be used as an error handler for asynchronous
 * calls, like those happening when issuing HTTP requests. When
 * the error handler catches an error, the error bubbles up to
 * the nearest <ErrorBoundary>-component in the React tree,
 * where it will be displayed by the fallback component
 * (see FallbackInCaseOfErrorComponent).
 *
 * If the error caught is a TokenExpiredError, this error
 * handler does not process it any further and swallows it,
 * because that specific error is already being handled by
 * the error handler in BaseService.
 *
 * Use this error handler like so:
 *
 * someService
 *   .doSomething()
 *   .then(...process the result...)
 *   .catch(useResiErrorHandler()))
 */
export const useResiErrorHandler = (): ((e: Error) => void) => {
  const { showBoundary } = useErrorBoundary();
  return useCallback(
    (e: Error) => {
      if (e instanceof TokenExpiredError) {
        return;
      }
      showBoundary(e);
    },
    [showBoundary]
  );
};
