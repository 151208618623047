import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { DetailedReservation } from "../_models/detailedReservation";
import {
  CommunicationChannel,
  useReservationService,
} from "../_services/reservation.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

import xss from "xss";
import { alertActions } from "../_actions/alert.actions";
import { useDispatch } from "react-redux";

export enum ReservationCompletionType {
  FULFILLING,
  DECLINING,
}

export type ReservationCompletion = {
  typeOfProcessing: ReservationCompletionType;
  reservation: DetailedReservation;
};

type ReservationModalProps = {
  completion: ReservationCompletion | null;
  onModalClose: () => void;
};

const getReservationDetails = (reservation: DetailedReservation) => {
  return (
    <React.Fragment>
      <Container fluid>
        <Row>
          <Col md={3} className="pe-0">
            <img
              className="img-responsive center-block"
              src={reservation.article.coverUrlMedium}
              alt="Cover"
            />
          </Col>
          <Col>
            <p>
              <span className="fw-bold">{reservation.article.title}</span>
              <br />
              {reservation.article.authors}
              <br />
              <span className="text-danger">
                {reservation.article.productType}
              </span>
              <br />
              {reservation.article.displayPrice}
              <br />
              EAN: {reservation.article.ean}
            </p>
          </Col>
        </Row>
      </Container>
      <br />
      <p>Für:</p>
      <p>
        {reservation.salutation}
        <br />
        {reservation.name}
        <br />
        <FontAwesomeIcon icon={faEnvelope} className="me-2" />
        {reservation.email}
        <br />
        {reservation.phone && (
          <React.Fragment>
            <FontAwesomeIcon icon={faPhone} className="me-2" />
            {reservation.phone}
          </React.Fragment>
        )}
      </p>
    </React.Fragment>
  );
};

const generateDefaultMailText = (
  completion: ReservationCompletion | null
): string => {
  if (!completion) {
    return "";
  } else {
    // const salutation =
    //   completion.reservation.salutation === "Frau"
    //     ? "Sehr geehrte Frau"
    //     : "Sehr geehrter Herr";
    // const surname = completion.reservation.name.split(", ")[0];
    // const articleString =
    //   completion.reservation.article.title +
    //   " - " +
    //   completion.reservation.article.authors;

    const text =
      // salutation +
      // " " +
      // surname +
      // "\n\nwir bedauern sehr Ihnen mitteilen zu müssen, dass wir Ihren Wunschtitel\n\n" +
      // articleString +
      // "\n\n" +
      // "nicht für Sie reservieren konnten.\n\n" +
      "Trotz der Aktualität unseres Online-Shops kann es im Einzelfall vorkommen, dass ein Titel zwischenzeitlich bei uns vergriffen ist, insbesondere wenn der Titel außerhalb unserer Öffnungszeit stark nachgefragt wird. Wir bemühen uns, dies durch weiter verbesserte Planung unseres Bestands zukünftig noch sicherer zu verhindern." +
      // "\n\n" +
      // "Gerne möchten wir Ihnen aber bei Ihrem Wunsch weiterhelfen.\n\n" +
      // "Dürfen wir Sie bei der Auswahl eines vorrätigen Alternativtitels beraten?\n\n" +
      // "Oder hat Ihr Wunsch noch einen Tag Zeit? Dann prüfen wir gerne, ob wir Ihren Wunschtitel zur Abholung am folgenden Werktag bestellen können.\n\n" +
      // "Bitte besuchen Sie uns oder rufen uns an!";
      "";

    return text;
  }
};

export const ReservationModal = ({
  completion,
  onModalClose,
}: ReservationModalProps): React.ReactElement | null => {
  const dispatch = useDispatch();

  const [declineViaEmail, setDeclineViaEmail] = useState<boolean>(false);
  const [customDeclineReservationText, setCustomDeclineReservationText] =
    useState<string>();
  const [submitted, setSubmitted] = useState(false);

  const submitConflictMessage =
    "Die Reservierungsanfrage wurde bereits bearbeitet und der Kunde informiert.\n" +
    "Weitere Schritte sind nicht erforderlich.\n" +
    "Bitte schließen Sie den Dialog mit dem Abbrechen-Button.";

  const reservationService = useReservationService();

  if (!completion) {
    return null;
  }

  if (completion.typeOfProcessing === ReservationCompletionType.FULFILLING) {
    const fulfillReservation = () => {
      reservationService
        .fulfillReservation(completion.reservation.id)
        .then(onModalClose)
        .catch(() => {
          dispatch(alertActions.modalNotification(submitConflictMessage));
        });
    };

    return (
      <Modal
        show={true}
        onHide={onModalClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="bg-primary text-white">
          <Modal.Title>Reservierung bestätigen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Bitte bestätigen Sie die Reservierung des Titels:</p>
          {getReservationDetails(completion.reservation)}
          <p className="font-weight-bold">
            Nach der Bestätigung erfolgt die Anlage eines KBV im Libri-JWWS
            automatisch.
          </p>
          <p>NACH der Bestätigung sind die Daten im Archiv einsehbar.</p>
          <p>
            Wenn Sie auf &quot;Bestätigen&quot; klicken, wird der Kunde
            automatisch per E-Mail über die Reservierung informiert.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="me-auto">
            <Button
              style={{ margin: "0.25em" }}
              variant="primary"
              onClick={fulfillReservation}
            >
              Bestätigen
            </Button>
            <Button
              style={{ margin: "0.25em" }}
              variant="secondary"
              onClick={onModalClose}
            >
              Abbrechen
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  } else if (
    completion.typeOfProcessing === ReservationCompletionType.DECLINING &&
    !declineViaEmail
  ) {
    const declineReservationViaPhone = () => {
      reservationService
        .declineReservation(
          completion.reservation.id,
          CommunicationChannel.PHONE
        )
        .then(onModalClose)
        .catch(() => {
          dispatch(alertActions.modalNotification(submitConflictMessage));
        });
    };

    const openDeclineViaEmailModal = () => {
      setDeclineViaEmail(true);
      setCustomDeclineReservationText(generateDefaultMailText(completion));
    };

    return (
      <Modal
        show={true}
        onHide={onModalClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="bg-primary text-white">
          <Modal.Title>Reservierung nicht möglich</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Die Reservierung des folgenden Titels ist nicht möglich:</p>
          {getReservationDetails(completion.reservation)}
          <p className="fw-bold">
            Wichtig:
            <br />
            Mit Klick auf &quot;Persönlich ablehnen&quot; müssen Sie den Kunden
            JETZT persönlich informieren. Eine automatische Information an den
            Kunden per E-Mail erfolgt NICHT.
          </p>
          <p>NACH der Bearbeitung sind die Daten im Archiv einsehbar.</p>
          <p className="fw-bold">
            Wenn Sie auf &quot;Ablehnen per E-Mail&quot; klicken, erfolgt eine
            automatische Information an den Kunden per E-Mail.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={declineReservationViaPhone}
            disabled={!completion.reservation.phone}
          >
            Persönlich ablehnen
          </Button>
          <Button variant="secondary" onClick={openDeclineViaEmailModal}>
            Ablehnen per E-Mail
          </Button>
          <Button variant="secondary" onClick={onModalClose}>
            Abbrechen
          </Button>
        </Modal.Footer>
      </Modal>
    );
  } else {
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setCustomDeclineReservationText(e.target.value);
    };

    const onDeclineViaMailModalReset = () => {
      setDeclineViaEmail(false);
      setCustomDeclineReservationText("");
    };

    const onDeclineViaMailModalClose = () => {
      onDeclineViaMailModalReset();
      onModalClose();
    };

    const declineReservationViaEmail = () => {
      if (
        !customDeclineReservationText ||
        customDeclineReservationText.trim().length === 0
      ) {
        setSubmitted(true);
        return;
      }

      const textBlockWithHtmlLineBreaks = customDeclineReservationText.replace(
        /(\r\n|\n|\r)/gm,
        "<br/>"
      );

      const textBlockSanitized = xss(textBlockWithHtmlLineBreaks, {
        // for configuration consult https://jsxss.com/en/options.html
        whiteList: { br: [] },
        stripIgnoreTag: true,
        stripIgnoreTagBody: ["script", "object"],
      });

      reservationService
        .declineReservation(
          completion.reservation.id,
          CommunicationChannel.EMAIL,
          textBlockSanitized
        )
        .then(onDeclineViaMailModalClose)
        .catch(() => {
          dispatch(alertActions.modalNotification(submitConflictMessage));
        });
    };

    return (
      <Modal
        show={true}
        onHide={onDeclineViaMailModalReset}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="bg-primary text-white">
          <Modal.Title>Reservierung per Email ablehnen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Die Reservierung des folgenden Titels ist nicht möglich:</p>
          {getReservationDetails(completion.reservation)}
          <p>
            Bevor Sie auf &quot;Abschicken&quot; klicken, können Sie den Text
            der Standard-Email auch nach Ihren Wünschen anpassen und den Kunden
            eine persönliche Rückmeldung geben:
          </p>
          <textarea
            className={
              "form-control" +
              (submitted &&
              (!customDeclineReservationText ||
                customDeclineReservationText.trim().length === 0)
                ? " is-invalid"
                : "")
            }
            id="customDeclinationText"
            rows={8}
            value={customDeclineReservationText}
            onChange={handleChange}
            maxLength={1000}
          />
          {submitted &&
            (!customDeclineReservationText ||
              customDeclineReservationText.trim().length === 0) && (
              <div className="invalid-feedback">
                Bitte informieren Sie den Kunden, warum die Reservierung nicht
                möglich ist
              </div>
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={declineReservationViaEmail}>
            Abschicken
          </Button>
          <Button
            className="me-auto"
            variant="secondary"
            onClick={onDeclineViaMailModalReset}
          >
            Abbrechen
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};
