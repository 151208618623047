import { CHOOSE_POINT_OF_SALE } from "../_actions/pointOfSale.actions";
import { PointOfSaleState } from "../_helpers/store";
import { localStorageHelper } from "../_helpers/localStorage";
import { LOGOUT } from "../_actions/authentication.actions";
import { AnyAction } from "redux";

export type ChoosePointOfSaleAction = {
  type: typeof CHOOSE_POINT_OF_SALE;
  data: {
    pointOfSaleAffiliateId: number;
    pointOfSaleName: string;
  };
};

const getInitialState = (): PointOfSaleState | null => {
  const existingSelectedPointOfSale = localStorageHelper.loadPointOfSale();
  if (!existingSelectedPointOfSale) {
    return null;
  }
  return {
    id: existingSelectedPointOfSale.pointOfSaleAffiliateId,
    name: existingSelectedPointOfSale.pointOfSaleName,
  };
};

export const pointOfSale = (
  state: PointOfSaleState | null = getInitialState(),
  action: AnyAction
): PointOfSaleState | null => {
  if (action.type === CHOOSE_POINT_OF_SALE) {
    return {
      id: action.data.pointOfSaleAffiliateId,
      name: action.data.pointOfSaleName,
    };
  }
  if (action.type === LOGOUT) {
    // A logout resets the point-of-sale selection:
    return null;
  }
  return state;
};

export type PointOfSaleAction = ChoosePointOfSaleAction;
