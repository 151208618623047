import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faSquare,
  faSync,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { DetailedReservation } from "../_models/detailedReservation";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../_helpers/store";
import {
  ReservationListCode,
  ReservationListResult,
  ReservationStatus,
  useReservationService,
} from "../_services/reservation.service";
import { alertActions } from "../_actions/alert.actions";
import {
  ReservationCompletion,
  ReservationCompletionType,
  ReservationModal,
} from "./ReservationModal";
import moment from "moment";
import { ReservationCoverZoomModal } from "./ReservationCoverZoomModal";
import { LogoutButton } from "../layout/LogoutButton";
import { pointOfSaleActions } from "../_actions/pointOfSale.actions";
import { authenticationActions } from "../_actions/authentication.actions";
import { Routes } from "../_helpers/routes";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Navbar from "react-bootstrap/Navbar";
import { SubscribeToNotificationsLink } from "../notification/SubscribeToNotificationsLink";
import { useSubscriptionService } from "../_services/subscription.service";
import { useResiErrorHandler } from "../_helpers/errorHandler";
import { useAffiliatePropertiesService } from "../_services/affiliateProperties.service";
import { useAuthenticationService } from "../_services/authentication.service";
import { useBaseService } from "../_services/base.service";
import "../App.css";

export const ReservationListView: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const errorBoundaryHandler = useResiErrorHandler();

  const baseService = useBaseService();
  const reservationService = useReservationService();
  const subscriptionService = useSubscriptionService();
  const affiliatePropertiesService = useAffiliatePropertiesService();
  const authenticationService = useAuthenticationService(baseService);

  // Which point of sale are we displaying reservations for?
  const pointOfSale = useSelector((state: RootState) => state.pointOfSale);

  if (!pointOfSale) {
    throw new Error("The pointOfSale should never be undefined!");
  }

  const pointOfSaleAffiliateId = pointOfSale.id;

  // Which point of sales are already subscribed?
  const subscribedPointOfSaleAffiliateIds = useSelector(
    (state: RootState) => state.subscription.subscribedPointOfSaleAffiliateIDs
  );

  // What is the list of reservations we are displaying?
  const [reservations, setReservations] = useState<
    DetailedReservation[] | null
  >(null);

  // What reservation are we currently processing in the separate modal?
  const [currentReservationCompletion, setCurrentReservationCompletion] =
    useState<ReservationCompletion | null>(null);
  const [currentReservationCover, setCurrentReservationCover] = useState<
    string | null
  >(null);
  const onReservationModalClose = () => {
    setCurrentReservationCompletion(null);
    setCurrentReservationCover(null);
    loadData(pointOfSaleAffiliateId).then(displayData);
    dispatch(alertActions.clear());
  };

  // How often (in seconds) should we refresh the reservation list automatically?
  const [refreshInterval, setRefreshInterval] = useState<number>();

  // Are we loading?
  const [loadingInProgess, setLoadingInProgess] = useState<boolean>(false);

  // Loads the data and returns a promise to it:
  const loadData = useCallback(
    async (
      pointOfSaleAffiliateId: number
    ): Promise<ReservationListResult | void> => {
      setLoadingInProgess(true);
      return reservationService
        .loadReservationList(pointOfSaleAffiliateId)
        .catch(errorBoundaryHandler)
        .finally(() => setLoadingInProgess(false));
    },
    [reservationService, errorBoundaryHandler]
  );

  // Displays the data loaded by "loadData".
  // Separating this from "loadData" is necessary because we need to handle
  // the case of the component already having unmounted in the useEffect hook
  // that displays the data when the list is first entered:
  const displayData = useCallback(
    (reservationListResult: ReservationListResult | void) => {
      if (!reservationListResult) {
        return;
      }
      if (reservationListResult.resultCode === ReservationListCode.Success) {
        setReservations(reservationListResult.data);
      } else if (
        reservationListResult.resultCode ===
        ReservationListCode.MissingPermission
      ) {
        dispatch(
          alertActions.error(
            "Sie sind leider nicht für die Bearbeitung von Reservierungsanfragen freigeschaltet. Bitte wenden Sie sich an Libri, wenn Sie Zugriff erhalten möchten."
          )
        );
      } else if (
        reservationListResult.resultCode === ReservationListCode.UnknownError
      ) {
        dispatch(
          alertActions.error(
            "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut."
          )
        );
      }
    },
    [dispatch]
  );

  const refresh = useCallback(() => {
    loadData(pointOfSaleAffiliateId).then(displayData);
  }, [loadData, displayData, pointOfSaleAffiliateId]);

  // Load and store the refresh interval once:
  useEffect(() => {
    affiliatePropertiesService
      .getAffiliateProperties()
      .then((properties) => {
        const refreshIntervalFromProperties =
          properties["/Shop/Artikel/Online-Reservierung/Refresh-Intervall"];
        if (refreshIntervalFromProperties) {
          setRefreshInterval(refreshIntervalFromProperties);
        }
      })
      .catch((e) => {
        // If something goes wrong while loading the refresh interval from the WSAPI,
        // set it to a sensible default instead of aborting here:
        console.error(
          `Error while loading refresh interval: ${e.name} - ${e.message}`
        );
        setRefreshInterval(300);
      });
  }, [affiliatePropertiesService]);

  // Use the refresh interval (once it's loaded) to set up a regular refresh ticker:
  useEffect(() => {
    if (refreshInterval) {
      let refreshTicker: NodeJS.Timeout | null = null;
      refreshTicker = setInterval(() => {
        if (!currentReservationCompletion) {
          refresh();
        }
      }, refreshInterval * 1000);
      return () => {
        if (refreshTicker) {
          clearInterval(refreshTicker);
        }
      };
    }
  }, [refreshInterval, refresh, currentReservationCompletion]);

  // Load the reservation data when the list is first entered:
  useEffect(() => {
    let mounted = true;
    loadData(pointOfSaleAffiliateId).then((result) => {
      // Only try to display the data (= modify the component state) if
      // the component is still mounted (might not be the case if we
      // navigate away from the list while the data is loading):
      if (mounted) {
        displayData(result);
      }
    });
    return function cleanup() {
      mounted = false;
    };
  }, [pointOfSaleAffiliateId, loadData, displayData, dispatch]);

  // If we are not already subscribed to this point of sale, do it now automatically:
  useEffect(() => {
    if (!subscribedPointOfSaleAffiliateIds.includes(pointOfSaleAffiliateId)) {
      subscriptionService.addSubscriptionForPointOfSale(
        pointOfSaleAffiliateId,
        subscribedPointOfSaleAffiliateIds
      );
    } else {
      // If we are already subscribed for this point of sale, "silently" renew the subscription
      // in order to ensure that we are using a fresh and reachable push URL for the notifications:
      subscriptionService.updateSubscriptionsForPointOfSale(
        new Set<number>(subscribedPointOfSaleAffiliateIds)
      );
    }
    // Important: Do not include "subscribedPointOfSaleAffiliateIds" in the dependency list for this hook!
    // If we do that, clicking the "deactivate notifications" button (which modifies subscribedPointOfSaleAffiliateIds)
    // will cause this hook here to run again, immediately *activating* notifications again. The user sees this as
    // a short "flash" of button and information modal.
    // TODO write a test for this - this is not at all easy because we have to test for
    //  "make sure that the button does not change back 'some time' after it has been clicked"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pointOfSaleAffiliateId, dispatch]);

  // When a notification for this client comes in, the service worker
  // will send a MessageEvent with "type" = "reloadReservations", so
  // that we know we need to reload the list of reservations.
  // When the notification is clicked by the user, the service worker
  // will send a MessageEvent with "type" = "showReservations" and
  // the "payload" field of the event will contain information about
  // the point of sale that reservation is for, so we can switch to
  // that point of sale directly:
  useEffect(() => {
    if (!("serviceWorker" in navigator)) {
      return;
    }
    navigator.serviceWorker.addEventListener(
      "message",
      (event: MessageEvent) => {
        switch (event.data.type) {
          case "reloadReservations":
            loadData(pointOfSaleAffiliateId).then(displayData);
            break;
          case "showReservations":
            dispatch(
              pointOfSaleActions.choosePointOfSale({
                pointOfSaleAffiliateId: Number(
                  event.data.payload.pointOfSaleAffiliateId
                ),
                pointOfSaleName: event.data.payload.pointOfSaleAffiliateName,
              })
            );
            loadData(pointOfSaleAffiliateId).then(displayData);
            break;
        }
      }
    );
  }, [loadData, displayData, pointOfSaleAffiliateId, dispatch]);

  const logout = () => {
    dispatch(
      authenticationActions.logout(subscriptionService, authenticationService)
    );
  };

  const toNavigationLink = () => {
    navigate(Routes.choosePointOfSaleRoute);
  };

  const _renderRefreshButton = () => {
    if (!loadingInProgess) {
      return (
        <Button
          variant="primary"
          onClick={refresh}
          className="border float-end"
        >
          Anzeige aktualisieren
          <FontAwesomeIcon icon={faSync} className="ms-2" />
        </Button>
      );
    }
    return (
      <Button variant="primary" className="border float-end" disabled>
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      </Button>
    );
  };

  const _renderBody = () => {
    if (!reservations) {
      return <span className="spinner-border spinner-border-sm me-1" />;
    } else if (reservations.length === 0) {
      return <h2>Keine Reservierungen vorhanden</h2>;
    } else {
      return (
        <React.Fragment>
          <ReservationModal
            completion={currentReservationCompletion}
            onModalClose={onReservationModalClose}
          />
          <ReservationCoverZoomModal
            onModalClose={onReservationModalClose}
            imageUrl={currentReservationCover}
          />
          {_renderTable(reservations)}
        </React.Fragment>
      );
    }
  };

  const _renderTable = (
    detailedReservations: DetailedReservation[]
  ): JSX.Element => {
    return (
      <div
        className="container-fluid justify-content-around table-responsive"
        style={{ padding: "1em" }}
      >
        <table
          className="table table-striped table-hover"
          style={{ margin: "0em" }}
        >
          {_renderTableDescription()}
          <tbody>
            {detailedReservations
              .filter(
                (detailedReservation) =>
                  detailedReservation.status === ReservationStatus.NEW
              )
              .map((detailedReservation: DetailedReservation, index: number) =>
                _renderTableEntry(detailedReservation, index)
              )}
          </tbody>
        </table>
      </div>
    );
  };

  const _renderTableDescription = () => {
    return (
      <thead>
        <tr>
          <th className="width-100 text-center">Nummer</th>
          <th className="width-100 text-center">Datum</th>
          <th className="width-100 text-center">Name</th>
          <th className="width-100 text-center">Cover</th>
          <th className="width-100 text-center">Titel</th>
          <th className="width-100 text-center">Autor</th>
          <th className="width-100 text-center">ean</th>
          <th className="width-100 text-center">Preis</th>
          <th className="width-100 text-center">Bestätigen</th>
          <th className="width-100 text-center">Ablehnen</th>
        </tr>
      </thead>
    );
  };

  const _renderTableEntry = (
    reservation: DetailedReservation,
    index: number
  ): JSX.Element => {
    const date: Date = reservation.reservationDate;
    const dateString = moment(date).format("D.M.YYYY");
    const timeString = moment(date).format("H:mm [Uhr]");
    const thumbsUpIcon = (
      <span
        className="fa-layers fa-fw fa-3x"
        style={{ cursor: "pointer" }}
        title="Reservierungsanfrage bestätigen"
        onClick={() => {
          // To ensure our session is still valid before starting to edit this reservation, reload data:
          loadData(pointOfSaleAffiliateId).then(displayData);
          setCurrentReservationCompletion({
            typeOfProcessing: ReservationCompletionType.FULFILLING,
            reservation: reservation,
          });
        }}
      >
        <FontAwesomeIcon icon={faSquare} color="green" />
        <FontAwesomeIcon icon={faThumbsUp} color="white" transform="shrink-6" />
      </span>
    );
    const thumbsDownIcon = (
      <span
        className="fa-layers fa-fw fa-3x"
        style={{ cursor: "pointer" }}
        title="Reservierungsanfrage ablehnen"
        onClick={() => {
          // To ensure our session is still valid before starting to edit this reservation, reload data:
          loadData(pointOfSaleAffiliateId).then(displayData);
          setCurrentReservationCompletion({
            typeOfProcessing: ReservationCompletionType.DECLINING,
            reservation: reservation,
          });
        }}
      >
        <FontAwesomeIcon icon={faSquare} color="red" />
        <FontAwesomeIcon icon={faThumbsDown} inverse transform="shrink-6" />
      </span>
    );

    return (
      <tr key={index}>
        <td className="text-center align-middle">{index + 1}</td>
        <td className="text-center align-middle">
          {dateString}
          <br />
          {timeString}
        </td>
        <td className="text-center align-middle">{reservation.name}</td>
        <td className="text-center align-middle">
          <img
            className="img-responsive center-block"
            style={{ cursor: "pointer" }}
            src={reservation.article.coverUrlSmall}
            alt="Cover"
            height="60"
            onClick={() => {
              setCurrentReservationCover(
                reservation.article.coverUrlExtraLarge
              );
            }}
          />
        </td>
        <td className="text-center align-middle">
          {reservation.article.title}
        </td>
        <td className="text-center align-middle">
          {reservation.article.authors}
        </td>
        <td className="text-center align-middle">{reservation.article.ean}</td>
        <td className="text-center align-middle">
          {reservation.article.displayPrice}
        </td>
        <td className="text-center align-middle ">{thumbsUpIcon}</td>
        <td className="text-center align-middle">{thumbsDownIcon}</td>
      </tr>
    );
  };

  const goToArchive = () => {
    navigate(Routes.archiveRoute);
  };

  return (
    <div className="h-100">
      <header>
        <Navbar
          bg="white"
          expand={false}
          fixed="top"
          className="border-bottom border-dark"
        >
          <div className="mx-auto">
            <div className="float-start">
              <Button
                variant="light"
                onClick={toNavigationLink}
                className="border"
              >
                <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                Andere Filiale auswählen
              </Button>
            </div>
            <div className="float-end btn-group">
              <div className="pe-2">
                <DropdownButton
                  id="dropdown-basic-button"
                  title="Reservierungsanfragen"
                  variant="secondary"
                >
                  <Dropdown.Item href="#/action-1">
                    Reservierungsanfragen
                  </Dropdown.Item>
                  <Dropdown.Item onClick={goToArchive}>Archiv</Dropdown.Item>
                </DropdownButton>
              </div>
              <div className="pe-2">
                <LogoutButton logoutAction={logout} />
              </div>
            </div>
          </div>
        </Navbar>
      </header>
      <main className="main flex-shrink-0" role="main">
        <div className="mx-auto">
          <h1>Bearbeitung von Reservierungsanfragen</h1>
          <h2>
            {pointOfSale.name}{" "}
            <SubscribeToNotificationsLink posAffiliateId={pointOfSale.id} />
            {_renderRefreshButton()}
          </h2>
          <div className="bg-white border border-dark">{_renderBody()}</div>
        </div>
      </main>
    </div>
  );
};
