const getCookie = (cookieName: string): string | undefined => {
  const cookie = document.cookie
    .split(";")
    .find((item) => item.trim().startsWith(cookieName + "="));
  if (!cookie) {
    return undefined;
  }
  return cookie.split("=")[1];
};

const deleteCookie = (cookieName: string): void => {
  document.cookie = `${cookieName}=; max-age=-1;`;
};

const clearApplicationData = (): void => {
  deleteCookie("tokenId");
  deleteCookie("tokenType");
  deleteCookie("csrfToken");
};

export const cookieHelper = {
  getCookie,
  deleteCookie,
  clearApplicationData,
};
