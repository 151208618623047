import React, { ReactElement, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useResiErrorHandler } from "../_helpers/errorHandler";

type ReloadModalProps = {
  intervalInMS: number;
};

export const ReloadModal = ({
  intervalInMS,
}: ReloadModalProps): ReactElement | null => {
  const [reloadNecessary, setReloadNecessary] = useState<boolean>(false);
  const errorHandler = useResiErrorHandler();
  useEffect(() => {
    const interval = setInterval(() => {
      fetch("app." + process.env.REACT_APP_GIT_SHA + ".json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Cache-Control": "no-cache, no-store, must-revalidate",
        },
      })
        .then(function (response) {
          if (response.status !== 200) {
            clearInterval(interval);
            setReloadNecessary(true);
          }
        })
        .catch((e: Error) => {
          clearInterval(interval);
          errorHandler(e);
        });
    }, intervalInMS);
    return () => {
      setReloadNecessary(false);
      clearInterval(interval);
    };
  }, [intervalInMS, errorHandler]);
  const reloadBrowser = () => {
    window.location.reload();
  };
  if (!reloadNecessary) {
    return null;
  }
  return (
    <Modal
      show={reloadNecessary}
      onHide={reloadBrowser}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header className="bg-primary text-white">
        <Modal.Title>Resi-Applikation wurde aktualisiert</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Die Resi-Applikation wurde aktualisiert und muss neu gestartet werden.
      </Modal.Body>
      <Modal.Footer>
        <div className="d-grid w-100">
          <Button
            variant="primary"
            type="button"
            size="lg"
            onClick={reloadBrowser}
          >
            Neu starten
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
