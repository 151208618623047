import {
  CODE_INVALID_CREDENTIALS,
  CODE_NO_VALID_TOKEN,
  CODE_SUCCESS,
  CODE_TOKEN_EXPIRED,
  MISSING_PERMISSION,
  RESERVATION_ALREADY_PROCESSED,
} from "./status-codes";
import { cookieHelper } from "../_helpers/cookies";
import {
  HttpStatusError,
  InvalidCredentialsError,
  InvalidJsonResponseError,
  MissingPermissionError,
  ReservationAlreadyProcessedError,
  TokenExpiredError,
  WebserviceError,
} from "./serviceErrors";
import { authenticationActions } from "../_actions/authentication.actions";
import { useAuthenticationService } from "./authentication.service";
import { useDispatch } from "react-redux";
import { useMemo } from "react";

export class BaseService {
  private readonly _tokenExpiredErrorHandler: (e: Error) => void;

  constructor(tokenExpiredErrorHandler: (e: Error) => void) {
    this._tokenExpiredErrorHandler = tokenExpiredErrorHandler;
  }

  async get(
    url: string,
    data: Record<string, string>,
    includeCsrfTokenOptional = true
  ): Promise<unknown> {
    const headers = getDefaultHeaders(includeCsrfTokenOptional);

    const queryParams = new URLSearchParams(data);

    const queryParamsString = queryParams.toString();
    const fetchResult = await fetch(
      url + (queryParamsString ? "?" + queryParamsString : ""),
      {
        credentials: "include",
        headers: headers,
        method: "GET",
        mode: "cors",
      }
    );

    return processResponse(fetchResult, url).catch((e: Error) =>
      this._tokenExpiredErrorHandler(e)
    );
  }

  async post(
    url: string,
    data: Record<string, string>,
    includeCsrfTokenOptional = true
  ): Promise<unknown> {
    const headers = getDefaultHeaders(includeCsrfTokenOptional);

    // For POST requests to the WSAPI, the body is encoded in the
    // same way as the query params for a GET request:
    headers["Content-Type"] = "application/x-www-form-urlencoded";
    const body = new URLSearchParams(data);

    const fetchResult = await fetch(url, {
      credentials: "include",
      headers: headers,
      body: body,
      method: "POST",
      mode: "cors",
    });

    return processResponse(fetchResult, url).catch(
      this._tokenExpiredErrorHandler
    );
  }
}

const getDefaultHeaders = (
  includeCrsfToken: boolean
): Record<string, string> => {
  const headers: Record<string, string> = {
    Accept: "*/*",
    Pragma: "no-cache",
    "Cache-Control": "no-cache",
  };
  if (includeCrsfToken) {
    const csrfToken: string | undefined = cookieHelper.getCookie("csrfToken");
    if (!csrfToken) {
      throw new Error("Could not load CSRF token from cookies");
    }
    headers["x-csrf-token"] = csrfToken;
  }
  return headers;
};

const processResponse = async (
  response: Response,
  url: string
): Promise<unknown> => {
  // The HTTP 204 No Content success status response code indicates that a request has succeeded,
  // but that the client doesn't need to do anything. We used it for our error tracking
  if (response.status === 204) {
    return;
  }
  if (response.status !== 200) {
    throw new HttpStatusError(url, response.status, response.statusText);
  }
  const json = await response.json();

  const resultCode = json["resultCode"];
  switch (resultCode) {
    case CODE_SUCCESS:
      // Do not throw anything for success, so the "default" case of this switch won't trigger:
      break;
    case undefined:
      throw new InvalidJsonResponseError(url, json);
    case CODE_NO_VALID_TOKEN:
    case CODE_TOKEN_EXPIRED:
      throw new TokenExpiredError(url, resultCode, json["resultText"]);
    case CODE_INVALID_CREDENTIALS:
      throw new InvalidCredentialsError(url, json["resultText"]);
    case MISSING_PERMISSION:
      throw new MissingPermissionError(url, resultCode, json["resultText"]);
    case RESERVATION_ALREADY_PROCESSED:
      throw new ReservationAlreadyProcessedError(json["resultText"]);
    default:
      throw new WebserviceError(url, resultCode, json["resultText"]);
  }

  const responseData: Record<string, unknown> = json["result"];
  if (responseData === undefined) {
    throw new InvalidJsonResponseError(url, json);
  }
  return responseData;
};

/**
 * Get a BaseService implementation that does not try to log the user out
 * if it encounters a TokenExpiredError. This kind of BaseService makes
 * sense for services used *within* the central error handler for exactly that
 * TokenExpiredError -- if we encounter one another instance of that error
 * (or any error, for that matter), it does not make a lot of sense to try
 * and handle those errors with more clean-up logic.
 */
export const useBaseServiceWithoutTokenExpiredErrorHandler =
  (): BaseService => {
    return useMemo(
      () =>
        new BaseService((e) => {
          console.error(
            `Unexpected error in baseService: ${e.name} - ${e.message}`
          );
        }),
      []
    );
  };

export const useBaseService = (): BaseService => {
  const dispatch = useDispatch();

  const baseServiceWithoutErrorHandler =
    useBaseServiceWithoutTokenExpiredErrorHandler();
  const authenticationServiceWithoutErrorHandler = useAuthenticationService(
    baseServiceWithoutErrorHandler
  );

  return useMemo(
    () =>
      new BaseService((e) => {
        if (e instanceof TokenExpiredError) {
          dispatch(
            authenticationActions.automaticLogout(
              authenticationServiceWithoutErrorHandler
            )
          );
        }
        throw e;
      }),
    [authenticationServiceWithoutErrorHandler, dispatch]
  );
};
